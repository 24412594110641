import React, { useState, useEffect, memo } from "react";

let isLocation = false;
export const checkLocationPermission = async () => {
  try {
    if ("geolocation" in navigator) {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      getLocation();
      return permission.state;
    } else {
      return "unsupported";
    }
  } catch (error) {
    return "error";
  }
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000,
        }
      );
    } else {
      reject(new Error("Geolocation is not supported in your browser."));
    }
  });
};

export const reverseGeoCode = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();

    return data;
  } catch (error) {
    return null;
  }
};

const AddressComponent = ({ address }) => {
  return (
    <div>
      {address ? (
        <p>
          {address.address.suburb} {address.address.county}{" "}
          {address.address.city} {address.address.postcode}
        </p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export let _address;
export let _coords;

export const useLoc = (parameters = {}) => {
  const [loc, setLoc] = useState({});
  const [error, setError] = useState("");
  const options = {
    enableHighAccuracy: true,
    maximumAge: 300000,
    timeout: 10000,
    ...parameters,
  };
  const resolve = async ({ coords }) => {
    try {
      const _address = await reverseGeoCode(coords.latitude, coords.longitude);
      // setLoc({ coords });
      setLoc({
        address: _address?.display_name,
        city: _address?.address.city || _address?.address.county,
        area: _address?.address.street,
        latitude: _address?.lat,
        longitude: _address?.lon,
      });
    } catch (err) {}
  };
  const reject = (error) => {
    setError(error.message);
  };
  useEffect(() => {
    if (!("geolocation" in navigator)) {
      setError(
        "GeoLocation is not supported in your browser. Please update your browser."
      );
      return;
    }
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  }, [options.enableHighAccuracy, options.timeout, options.maximumAge]);
  return { ...loc, error };
};

const LocationComponent = () => {
  const [address, setAddress] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        isLocation = true;
        _coords = await getLocation();
        _address = await reverseGeoCode(_coords.latitude, _coords.longitude);
        setAddress(_address);
        isLocation = false;
      } catch (error) {}
    };
    if (!address && !isLocation) fetchData();
  }, []);

  return (
    <div>
      <AddressComponent address={address} />
    </div>
  );
};

export default memo(LocationComponent);
