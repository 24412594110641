import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";

import style from "./BusinessSearch.module.css";

import { IconComponent } from "../../Icons/IconComponent";
import {
  _address,
  _coords,
  checkLocationPermission,
  getLocation,
  reverseGeoCode,
  useLoc,
} from "../../utils/LocationComponent";
import colors from "../../assets/colors";
import {
  Context,
  Ping,
  iconImageUrl,
} from "../../Components/pages/Context/Context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Modal } from "@mui/material";
import LocationModal from "../../utils/LocationModal/LocationModal";
import BusinessCard from "../../Components/pages/Cards/BussinessCards/BusinessCard";
import backgroundImage from "../../../src/assets/Images/1.png";

import SuggestionCard from "../../Components/pages/Cards/SuggestionCard/SuggestionCard";
import { useWindowWidth } from "@react-hook/window-size";
import Iconsline from "../Iconsline";
import LoadingScreen, {
  LoadingResults,
} from "../../Components/pages/Loading/LoadingScreen";
import MediaQuery from "react-responsive";
import { toast } from "react-toastify";
import { DisplayCustomerApp } from "../../Components/pages/Customer/CustomerPlayStore";

let businessResults = {
  main: true,
  mainCall: false,
  mainAbort: false,
  secondary: true,
  secondaryCall: false,
  secondaryAbort: false,
  suggestion: true,
  suggestionCall: false,
  suggestionAbort: false,
};

let serviceResults = {
  main: true,
  mainCall: false,
  mainAbort: false,
  secondary: true,
  secondaryCall: false,
  secondaryAbort: false,
  suggestion: true,
  suggestionCall: false,
  suggestionAbort: false,
};
let businessOffset = 0;
let serviceOffset = 0;
let suggestionBusinessOffset = 0;
let suggestionServiceOffset = 0;
let resultsCount = 0;
let searchItem = {};
let isIconSearch = false;
let getIcons = true;
let modalShown = false;
export let handleFilter = () => {};
let _deals = false,
  _homeDelivery = false,
  _selectedItem = "";

// export const SectionHeader = ({ section }) => {
//   const suggestionResults = section?.filter((el) => el.horizontal === true);
//   return (
//     <>
//       {suggestionResults?.map((el) =>
//         el.data.map((item) => <SuggestionCard item={item} />)
//       )}
//     </>
//   );
// };

// export const RenderCards = memo(({ items }) => {
//   if (!(items ?? []).length) {
//     return <></>;
//   }
//   const businessResults = items?.filter((el) => el.horizontal !== true);
//   console.log("businessResults:", businessResults);
//   console.log("items:", items);
//   return (
//     <>
//       {businessResults?.map((el) =>
//         el.data.map((item) => <BusinessCard item={item} />)
//       )}
//     </>
//   );
// });

const BusinessSearch = () => {
  const width = useWindowWidth();
  const { latitude, longitude, error, city, address } = useLoc();
  const navigate = useNavigate();
  const location = useLocation();

  const [foregroundModal, setForegroundModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [finalResults, setFinalResults] = useState([]);
  const { baseUrl, sessionId, setIconSearch, setIsLoggedIn, searchIcons } =
    useContext(Context);
  const [filteredList, setFilteredList] = useState([]);
  const [suggestions, setSuggestions] = useState(false);
  const [mainResultsLoading, setMainResultsLoading] = useState(false);
  const [suggestionResultsLoading, setSuggestionResultsLoading] =
    useState(false);
  const [iconsList, setIconsList] = useState(searchIcons);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [search, setSearch] = useState("");
  const [granted, setGranted] = useState(false);
  const inputRef = useRef(null);
  const hscrollRef = useRef();
  const vscrollRef = useRef();

  const appState = useRef(document.visibilityState);
  const [coords, setCoords] = useState({});

  useEffect(() => {
    window.addEventListener("scroll", handleVerticalScroll);
    return () => {
      window.removeEventListener("scroll", handleVerticalScroll);
    };
  }, []);
  useEffect(() => {
    if (error) {
    }
  }, [latitude, longitude, error, address]);
  const handleHorizontalScroll = () => {
    if (hscrollRef.current) {
      const { scrollTop, scrollLeft, scrollWidth, offsetWidth } =
        hscrollRef.current;
      if (scrollLeft + offsetWidth + 500 >= scrollWidth)
        handleSuggestionEndReached();
    }
  };
  const handleVerticalScroll = () => {
    if (vscrollRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, offsetHeight } =
        vscrollRef.current;
      if (scrollTop + offsetHeight + 500 >= scrollHeight) handleEndReached();
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    localStorage.setItem("search", search);
    const newUrl = `?${params.toString()}`;
    if (newUrl !== location.search) {
      navigate(newUrl, { replace: true });
    }
  }, [search, navigate, location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialSearch = params.get("search");
    if (initialSearch) {
      setSearch(initialSearch);
    } else {
      const storedSearch = localStorage.getItem("search");
      if (storedSearch) {
        setSearch(storedSearch);
      }
    }
  }, [location.search]);

  const searchResults = useCallback(
    async (obj, x) => {
      const controller = new AbortController();
      try {
        setLoading(true);
        searchItem = obj;
        if (obj.isSuggestion) {
          obj.searchType === "business"
            ? (businessResults.suggestionAbort = controller)
            : (serviceResults.suggestionAbort = controller);
          setSuggestionResultsLoading(true);
        } else if (obj.isSecondarySearch) {
          obj.searchType === "business"
            ? (businessResults.secondaryAbort = controller)
            : (serviceResults.secondaryAbort = controller);
          setMainResultsLoading(true);
        } else {
          obj.searchType === "business"
            ? (businessResults.mainAbort = controller)
            : (serviceResults.mainAbort = controller);
          setMainResultsLoading(true);
        }
        !obj.isSecondarySearch &&
        !obj.isSuggestion &&
        obj.searchType === "business"
          ? (businessResults.mainCall = true)
          : (serviceResults.mainCall = true);

        const _obj = {
          ...obj,
        };
        const response = await fetch(`${baseUrl}/api/search/v2`, {
          signal: controller.signal,
          method: "POST",
          headers: { "Content-Type": "application/json", sessionId },
          body: JSON.stringify(obj),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseJson = await response.json();

        if (response.code === 403) {
          reLogin();
          return;
        }

        arrangeResults({
          field: obj.searchType,
          content: responseJson,
          isSecondarySearch: obj.isSecondarySearch,
          isSuggestion: obj.isSuggestion,
        });
      } catch (err) {
        controller.abort();
      }
    },
    [sessionId]
  );
  const arrangeResults = (_results) => {
    const total = _results.content.length;
    const emptyResults = total === 0;
    resultsCount = resultsCount + total;
    if (_results.isSuggestion) {
      const index = finalResults.findIndex((el) => el.horizontal);
      if (_results.field === "business") {
        businessResults.suggestion = !emptyResults;
        businessResults.suggestionCall = false;
        businessResults.suggestionAbort = false;
        suggestionBusinessOffset = emptyResults
          ? 0
          : suggestionBusinessOffset + 18;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const response = [...prev];
            response[index]
              ? (response[index].data = [
                  ...response[index].data,
                  ..._results.content,
                ])
              : response.push({ data: _results.content, horizontal: true });
            localStorage.setItem("finalResults", JSON.stringify(response));

            return response;
          });
          return;
        }
      } else {
        serviceResults.suggestion = !emptyResults;
        serviceResults.suggestionCall = false;
        serviceResults.suggestionAbort = false;
        suggestionServiceOffset = emptyResults
          ? 0
          : suggestionServiceOffset + 25;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const respone = [...prev];
            respone[index]
              ? (respone[index].data = [
                  ...respone[index].data,
                  ..._results.content,
                ])
              : respone.push({ data: _results.content, horizontal: true });
            localStorage.setItem("finalResults", JSON.stringify(respone));
            return respone;
          });
          return;
        }
      }
      setSuggestionResultsLoading(false);
    } else if (_results.isSecondarySearch) {
      if (_results.field === "business") {
        businessResults.secondary = !emptyResults;
        businessResults.secondaryCall = false;
        businessResults.secondaryAbort = false;
        businessOffset = emptyResults ? 0 : businessOffset + 25;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const respone = [...prev, { data: _results.content }];
            localStorage.setItem("finalResults", JSON.stringify(respone));

            return respone;
          });
          return;
        }
      } else {
        serviceResults.secondary = !emptyResults;
        serviceResults.secondaryCall = false;
        serviceResults.secondaryAbort = false;
        serviceOffset = emptyResults ? 0 : serviceOffset + 15;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const response = [...prev, { data: _results.content }];
            localStorage.setItem("finalResults", JSON.stringify(response));

            return response;
          });
          return;
        }
      }
      setMainResultsLoading(false);
    } else {
      if (_results.field === "business") {
        businessResults.main = !emptyResults;
        businessResults.mainCall = false;
        businessResults.mainAbort = false;
        if (
          !businessResults.main &&
          !businessResults.secondaryCall &&
          businessOffset === 0
        ) {
          businessResults.secondaryCall = true;
          searchResults({
            ...searchItem,
            isSecondarySearch: true,
            isSuggestion: false,
            offset: 0,
            searchType: "business",
          });
        }
        if (
          (!businessResults.main || finalResults.length > 0) &&
          !businessResults.suggestionCall &&
          suggestionBusinessOffset === 0 &&
          searchItem.searchTerm &&
          searchItem.searchTerm !== "near me"
        ) {
          businessResults.suggestionCall = true;
          searchResults({
            ...searchItem,
            isSecondarySearch: false,
            isSuggestion: true,
            offset: 0,
            searchType: "business",
          });
        }
        businessOffset = emptyResults ? 0 : businessOffset + 15;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const respone = [...prev, { data: _results.content }];
            localStorage.setItem("finalResults", JSON.stringify(respone));

            return respone;
          });
          return;
        }
      } else {
        serviceResults.main = !emptyResults;
        serviceResults.mainCall = false;
        serviceResults.mainAbort = false;
        if (
          !serviceResults.main &&
          !serviceResults.secondaryCall &&
          serviceOffset === 0
        ) {
          serviceResults.secondaryCall = true;
          searchResults({
            ...searchItem,
            isSecondarySearch: true,
            isSuggestion: false,
            offset: 0,
            searchType: "service",
          });
        }
        if (
          (!serviceResults.main || finalResults.length > 0) &&
          !serviceResults.suggestionCall &&
          suggestionServiceOffset === 0 &&
          searchItem.searchTerm &&
          searchItem.searchTerm !== "near me"
        ) {
          serviceResults.suggestionCall = true;
          searchResults({
            ...searchItem,
            isSecondarySearch: false,
            isSuggestion: true,
            offset: 0,
            searchType: "service",
          });
        }
        serviceOffset = emptyResults ? 0 : serviceOffset + 25;
        if (!emptyResults) {
          setFinalResults((prev) => {
            const respone = [...prev, { data: _results.content }];
            localStorage.setItem("finalResults", JSON.stringify(respone));

            return respone;
          });
          return;
        }
      }
      if (
        !businessResults.main &&
        !serviceResults.main &&
        businessOffset === 0 &&
        serviceOffset === 0 &&
        resultsCount === 0
      ) {
        noResultsCall();
      }
      setMainResultsLoading(false);
    }
    // setLoading(false);
    if (
      !businessResults.main &&
      !serviceResults.main &&
      !businessResults.secondary &&
      !serviceResults.secondary &&
      !businessResults.suggestion &&
      !serviceResults.suggestion &&
      resultsCount === 0
    ) {
      alert(
        "Dear User,\nWe did not find what you are looking for, however we are expanding."
      );
      reset();
    }
  };

  useEffect(() => {
    const storageData = localStorage.getItem("finalResults");
    const data = JSON.parse(storageData);
    setFinalResults(data);
    resultsCount = 0;
    data?.map((el) => {
      resultsCount = resultsCount + el.data.length;
    });
  }, []);
  const noResultsCall = async () => {
    try {
      const noResultsObject = {
        ...searchItem,
        usrType: "customer",
        isIconSearch: isIconSearch,
      };
      let Response = await fetch(`${baseUrl}/api/search/noResults`, {
        method: "Post",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(noResultsObject),
      });
      isIconSearch = false;
    } catch (err) {}
  };

  const handleSuggestionEndReached = () => {
    if (
      businessResults.suggestion &&
      !businessResults.suggestionCall &&
      "searchTerm" in searchItem &&
      searchItem.searchTerm !== "near me" &&
      suggestionBusinessOffset > 0
    ) {
      businessResults.suggestionCall = true;
      // setSuggestionResultsLoading(true)
      searchResults({
        ...searchItem,
        isSecondarySearch: false,
        isSuggestion: true,
        offset: suggestionBusinessOffset,
        searchType: "business",
      });
    }
    if (
      serviceResults.suggestion &&
      !serviceResults.suggestionCall &&
      "searchTerm" in searchItem &&
      searchItem.searchTerm !== "near me" &&
      suggestionServiceOffset > 0
    ) {
      serviceResults.suggestionCall = true;
      // setSuggestionResultsLoading(true)
      searchResults({
        ...searchItem,
        isSecondarySearch: false,
        isSuggestion: true,
        offset: suggestionServiceOffset,
        searchType: "service",
      });
    }
  };
  const abortAllCalls = () => {
    if (businessResults.mainAbort) {
      businessResults.mainAbort.abort();
    }
    if (businessResults.secondaryAbort) {
      businessResults.secondaryAbort.abort();
    }
    if (businessResults.suggestionAbort) {
      businessResults.suggestionAbort.abort();
    }
    if (serviceResults.mainAbort) {
      serviceResults.mainAbort.abort();
    }
    if (serviceResults.secondaryAbort) {
      serviceResults.secondaryAbort.abort();
    }
    if (serviceResults.suggestionAbort) {
      serviceResults.suggestionAbort.abort();
    }
  };

  function reset() {
    abortAllCalls();
    setFinalResults([]);
    setLoading(false);
    setSearch("");
    businessResults = {
      main: true,
      mainCall: false,
      mainAbort: false,
      secondary: true,
      secondaryCall: false,
      secondaryAbort: false,
      suggestion: true,
      suggestionCall: false,
      suggestionAbort: false,
    };
    serviceResults = {
      main: true,
      mainCall: false,
      mainAbort: false,
      secondary: true,
      secondaryCall: false,
      secondaryAbort: false,
      suggestion: true,
      suggestionCall: false,
      suggestionAbort: false,
    };
    businessOffset = 0;
    serviceOffset = 0;
    suggestionBusinessOffset = 0;
    suggestionServiceOffset = 0;
    resultsCount = 0;
    searchItem = {};
    _selectedItem = "";
    isIconSearch = false;
  }
  const handleEndReached = () => {
    if (resultsCount === 0) return;
    if (businessResults.main && !businessResults.mainCall) {
      businessResults.mainCall = true;
      searchResults({
        ...searchItem,
        isSecondarySearch: false,
        isSuggestion: false,
        offset: businessOffset,
        searchType: "business",
      });
    }
    if (serviceResults.main && !serviceResults.mainCall) {
      serviceResults.mainCall = true;
      searchResults({
        ...searchItem,
        isSecondarySearch: false,
        isSuggestion: false,
        offset: serviceOffset,
        searchType: "service",
      });
    }
    if (
      !businessResults.main &&
      businessResults.secondary &&
      !businessResults.secondaryCall
    ) {
      businessResults.secondaryCall = true;
      searchResults({
        ...searchItem,
        isSecondarySearch: true,
        isSuggestion: false,
        offset: businessOffset,
        searchType: "business",
      });
    }
    if (
      !serviceResults.main &&
      serviceResults.secondary &&
      !serviceResults.secondaryCall
    ) {
      serviceResults.secondaryCall = true;
      searchResults({
        ...searchItem,
        isSecondarySearch: true,
        isSuggestion: false,
        offset: serviceOffset,
        searchType: "service",
      });
    }
  };

  const handleSelect = (el) => {
    setSearchModal(false);
    setSuggestions(false);
    _selectedItem = el;
    handleSearch(el);
  };
  handleFilter = () => {
    reset();
    callResults("business");
  };
  useEffect(() => {
    const hideSubscription = window.addEventListener("keyboardDidHide", () => {
      inputRef.current?.blur();
    });

    return () => {
      hideSubscription?.remove();
    };
  }, []);

  const handleSearch = (_term, iconSearch) => {
    const _searchTerm = _term?.toLowerCase()?.trim();

    if (!_searchTerm) {
      toast.info("Dear User, Please enter your query");
      return;
    }

    reset();

    setSearch(_searchTerm);
    if (iconSearch) {
      if (!granted) checkPermission();
      else fetchLocation();
      _selectedItem = _searchTerm;
      isIconSearch = iconSearch;
    }

    const index = filteredList.findIndex((el) => el === _searchTerm);

    if (_selectedItem) {
      callResults("business");
      callResults("service");
      recordCall(_searchTerm);
    } else if (index === -1) {
      checkService(_searchTerm);
    } else if (index !== -1) {
      _selectedItem = filteredList[index];
      callResults("business");
      callResults("service");
      recordCall(_searchTerm);
    } else {
      // console.log(
      //   "We did not find what you are looking for, however we are expanding."
      // );
      toast.info(
        "Dear User, We did not find what you are looking for, however we are expanding."
      );
      recordCall();
    }
  };
  // const optimiseVersion = useDebounceCallback(handleSearch);
  useEffect(() => {
    const subscription = async () => {
      const nextAppState = document.visibilityState;
      const state = localStorage.getItem("state");

      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "visible" &&
        state
      ) {
        let Response = localStorage.getItem("basicDetails");
        let ResponseJson = await JSON.parse(Response);
        Ping(baseUrl, ResponseJson.sessionId);
        localStorage.setItem("state", "");
      }
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active" &&
        !foregroundModal
      ) {
        checkPermission();
      }
      appState.current = nextAppState;
    };

    document.addEventListener("visibilitychange", subscription);

    return () => {
      document.removeEventListener("visibilitychange", subscription);
    };
  }, []);
  // useEffect(() => {
  //   console.log("useEffect one time check permission");
  //   if (!coords?.loc) {
  //     checkPermission();
  //     console.log("calling check permission", coords, !coords?.loc);
  //   }
  // }, []);
  const callResults = (type) => {
    // if (coords.loc && coords.loc.latitude && coords.loc.longitude) {
    if (latitude && longitude) {
      const obj = {
        latitude,
        longitude,
        // latitude: coords.loc.latitude,
        // longitude: coords.loc.longitude,
        limit: 15,
        distanceInMts: 29 * 1000,
        offset: 0,
        isDeal: _deals,
        isHomeDelivery: _homeDelivery,
        searchType: type,
        isSecondarySearch: false,
        isSuggestion: false,
      };
      if (_selectedItem) {
        obj["searchTerm"] = _selectedItem;
      }
      searchResults(obj);
      setSuggestions(false);
    }
  };
  const checkService = async (t) => {
    try {
      const Response = await fetch(`${baseUrl}/api/search/find?search=${t}`, {
        headers: { sessionId },
      });
      const json = await Response.json();
      if (!Response.ok) {
        alert("Dear User,\nSomething went wrong. Please try again.");
      } else if (json.length > 0) {
        _selectedItem = t;
        callResults("business");
        callResults("service");
        recordCall(t);
      } else {
        alert(
          "Dear User,\nWe did not find what you are looking for, however we are expanding."
        );
        recordCall();
      }
    } catch (err) {}
  };
  const searchCall = async (signal) => {
    try {
      let Response = await fetch(
        `${baseUrl}/api/search/find?search=${search
          .trim()
          .toLocaleLowerCase()}`,
        {
          signal,
          headers: { sessionId },
        }
      );
      let ResponseJson = await Response.json();

      if (ResponseJson.length > 0) {
        setSuggestions(true);
        setFilteredList(ResponseJson);
      } else {
        setSuggestions(false);
        setFilteredList([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (search?.length > 2 && inputRef.current === document.activeElement) {
      searchCall(signal);
    }

    return () => controller.abort();
  }, [search]);

  const recordCall = async (term) => {
    try {
      const available = term
        ? true
        : _selectedItem === search.toLowerCase().trim();
      const record = {
        searchTerm: term ? term : search.toLowerCase().trim(),
        usrType: "customer",
        ...coords,
        isSearchTermAvailable: available,
        isDeal: _deals,
        isHomeDelivery: _homeDelivery,
        isIconSearch: isIconSearch,
      };
      let Response = await fetch(`${baseUrl}/api/search/record`, {
        method: "Post",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(record),
      });
      isIconSearch = false;
    } catch (err) {}
  };
  const checkPermission = async () => {
    try {
      const status = await checkLocationPermission();
      if (status === "granted") {
        fetchLocation();
        setGranted(true);
        return;
      } else if (status === "denied") {
        toast.info(
          "Dear user,\nYou won't be able to use our services without granting location permission. To grant permission go to site settings, grant Location permission and reload the page."
        );
        inputRef.current?.blur();
      } else if (status === "prompt") {
        if (modalShown) {
          fetchLocation();
        } else {
          setForegroundModal(true);
          inputRef.current?.blur();
        }
      }
    } catch (err) {}
  };
  // const fetchPermission = async () => {
  //   const foregroundPermission =
  //     await Location.requestForegroundPermissionsAsync();
  //   if (foregroundPermission.granted) {
  //     fetchLocation();
  //     setGranted(true);
  //   } else {
  //     setSettings(true);
  //   }
  // };
  const fetchLocation = async () => {
    try {
      const _coords = await getLocation();
      const _address = await reverseGeoCode(
        _coords.latitude,
        _coords.longitude
      );
      const _city = _address.address.city || _address.address.county;
      if (_address && _address.address && _city) {
        setCoords({
          loc: _coords,
          area: _address.address.street,
          city: _city,
        });
      } else {
      }
    } catch (err) {}
  };

  // useEffect(() => {
  //   if (!coords.loc) checkPermission();
  // }, []);
  // useEffect(() => {
  //   if (iconSearchTerm) {
  //     setSearch(iconSearchTerm);
  //     _selectedItem = iconSearchTerm;
  //     isIconSearch = true;
  //     callResults("business");
  //     callResults("service");
  //     recordCall(iconSearchTerm);
  //     setIconSearchTerm("");
  //     searchResults(iconSearchTerm);
  //   }
  // }, [iconSearchTerm]);

  // const [loading, setLoading] = useState(false);

  const delay = 5000;

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);
  useEffect(() => {
    if (sessionId && coords.city && getIcons) {
      // setHomeScreenLoading(true);
      getSearchIcons();
      getIcons = false;
    }
  }, [sessionId, coords]);
  const getSearchIcons = async () => {
    try {
      let Response = await fetch(`${baseUrl}/api/icons/get`, {
        headers: { sessionId, city: coords.city },
      });
      let ResponseJson = await Response.json();
      if (ResponseJson.code === 403) {
        reLogin();
        return;
      }
      setIconSearch(ResponseJson);
      setIconsList(ResponseJson);
    } catch (err) {}
  };
  // useEffect(() => {
  //   if (sessionId && coords.city && getIcons) {
  //     getSearchIcons();
  //     getIcons = false;
  //   }
  // }, [sessionId, coords]);
  const reLogin = () => {
    alert("Access denied!", "Please Login to your account", [
      {
        text: "Go to Login",
        onClick: () => {
          localStorage.setItem("isLoggedIn", "false");
          setIsLoggedIn(false);
        },
      },
    ]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" && selectedItem > 0) {
      setSelectedItem((prev) => prev - 1);
    } else if (
      e.key === "ArrowDown" &&
      selectedItem < filteredList.length - 1
    ) {
      setSelectedItem((prev) => prev + 1);
    } else if (e.key === "Enter") {
      if (selectedItem !== -1 && filteredList[selectedItem]) {
        handleSelect(filteredList[selectedItem]);
      }
    }
  };

  return (
    <>
      <div className={style.businessmainContainer}>
        <div className={style.businessSearchcontainer}>
          <div
            className={style.searchmodal}
            // style={
            //   resultsCount === 0 ? { margin: "center" } : { marginTop: 10 }
            // }
          >
            {/* <IconComponent
                  icon="logo"
                  left={-1}
                  iconFamily="IcoMoon"
                  iconStyle={{ left: -1 }}
                  sx={{ color:'#AF002B' ,marginRight:1}}
                  size={24}
                  color="brown"
                  /> */}

            <input
              type="text"
              ref={inputRef}
              value={search}
              inputMode="search"
              placeholder="pharmacy,Plumber,etc...."
              onChange={(event) => {
                const text = event.target.value;

                if (text.length == 0) _selectedItem = "";
                setSearch(text);
              }}
              onClick={() => {
                inputRef.current.focus();
                if (!granted) checkPermission();
                else fetchLocation();
                _selectedItem = "";
              }}
              onKeyDown={handleKeyDown}
            />

            <button
              onClick={() => {
                handleSearch(search);
              }}
            >
              <SearchIcon
                icon="search"
                size={20}
                sx={{ fontSize: [20, 20], color: "#AF002B", marginLeft: 1 }}
              />
            </button>
          </div>
          <>
            {suggestions &&
              search.length > 2 &&
              filteredList.map((el, j) => (
                <button
                  key={j}
                  onClick={() => {
                    handleSelect(el);
                  }}
                  className={`${style.suggestionTable} ${
                    j === selectedItem ? style.selectedSuggestion : ""
                  }`}
                >
                  <SearchIcon name="search" size={20} color={colors.primary} />
                  <p style={{ margin: 2 }}>{el}</p>
                </button>
              ))}
          </>
        </div>
        <MediaQuery minWidth={600}>
          <Iconsline iconList={iconsList} handleSearch={handleSearch} />
        </MediaQuery>
        <MediaQuery maxWidth={599}>
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              // margin:"0.5rem"
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <DisplayCustomerApp />
            {resultsCount === 0 && (
              <HomeIcons iconList={iconsList} handleSearch={handleSearch} />
            )}
          </div>
        </MediaQuery>
        <Modal
          open={foregroundModal}
          onClose={() => {
            setForegroundModal(false);
            modalShown = true;
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LocationModal
            title="OneStup uses location"
            text="Your location will be used to find you on a map and access with businesses and service providers around you."
            cancelButton="Deny"
            allowButton="Accept"
            onAllow={() => {
              checkPermission();
              setForegroundModal(false);
              modalShown = true;
            }}
            onCancel={() => {
              setForegroundModal(false);
              modalShown = true;
            }}
          />
        </Modal>
        {/* <Modal open={settings} onBackdropPress={() => setSettings(false)}>
            <LocationModal
              title="Grant Location Access"
              text="We need your permission to access your Location."
              cancelButton="Cancel"
              allowButton="Go to Settings"
              onAllow={() => {
                // Redirect to settings page for location permission
                setSettings(false);
              }}
              onCancel={() => {
                window.close();
                return;
              }}
            />
          </Modal> */}

        {/* <div
            style={{ margin: "10px", width: "100%" }}
            ref={hscrollRef}
            onScroll={handleHorizontalScroll}
            > */}

        <div
          className={style.businessCardcontainer}
          ref={vscrollRef}
          onScroll={handleVerticalScroll}
        >
          {/* <RenderCards items={finalResults} /> */}
          {finalResults?.map((el, _, arr) => {
            if (el.horizontal) {
              const _suggestionResults = arr?.filter(
                (res) => res.horizontal === true
              );
              return (
                <div
                  className={style.scrollcontainer}
                  ref={hscrollRef}
                  onScroll={handleHorizontalScroll}
                >
                  {_suggestionResults?.map((_res) =>
                    _res.data.map((item) => <SuggestionCard item={item} />)
                  )}
                  {suggestionResultsLoading && resultsCount > 0 && (
                    <>
                      <LoadingResults />
                      <LoadingResults />
                      <LoadingResults />
                    </>
                  )}
                </div>
              );
            } else {
              return (
                <>
                  {el.data.map((item) => (
                    <BusinessCard key={item.id} item={item} />
                  ))}
                </>
              );
            }
          })}
          {mainResultsLoading && (
            <>
              <LoadingResults width={width} />
              <LoadingResults width={width} />
              <LoadingResults width={width} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BusinessSearch;

export const HomeIcons = ({ iconList, handleSearch }) => {
  const navigate = useNavigate();
  const containerRef = useRef();
  const [layout, setLayout] = useState({});

  const [loading, setLoading] = useState(false);
  const CIRCLE_SIZE = window.innerWidth;

  const iconSize = 60;
  const center = CIRCLE_SIZE / 2;
  const radius = CIRCLE_SIZE / 2 - 10;
  const iconsCount = iconList.length;
  const delay = 5000;
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);
  useEffect(() => {
    if (containerRef.current) {
      setLayout({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [iconList]);

  const getButtonStyle = (i, coord) => {
    const angleRad = (((i * 360) / iconsCount) * Math.PI) / 180;
    const offset =
      coord === "x"
        ? (iconSize / 2) * (1 + Math.cos(angleRad))
        : (iconSize / 2) * (1 - Math.sin(angleRad));
    const _pos =
      coord === "x"
        ? radius * Math.cos(angleRad) - offset
        : radius * Math.sin(angleRad) + offset;
    const pos = _pos + center;
    return pos;
  };

  return (
    <div
      ref={containerRef}
      className={style.mobileIconsContainer}
      style={{
        width: window.innerWidth,
        height: window.innerHeight - 100,
        position: "relative",
      }}
    >
      {/* <div style={{
        margin:"0.5rem"
      }}>
      <DisplayCustomerApp/>
      </div> */}
      <button
        className={style.centerButtonStyle}
        style={{
          position: "absolute",
          left: center - 40,
          top: center + 22,
        }}
        onClick={() => {
          if (handleSearch) {
            handleSearch("near me", true);
          }
        }}
      >
        <p className="nearFont">Near me</p>
      </button>
      {iconList.map((el, i) => (
        <button
          key={i}
          className={style.customButton}
          style={{
            left: getButtonStyle(i, "x"),
            top: getButtonStyle(i, "y"),
            outline:"none",
            background:"none"
          }}
          onClick={() => {
            if (handleSearch) {
              handleSearch(el.searchTerm, true);
            }
          }}
        >
          <img
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "30px",
            }}
            src={`${iconImageUrl + el.image}`}
            alt="icon"
          />
          <p className={style.iconName}>{el.searchTerm}</p>
        </button>
      ))}
    </div>
  );
};

export let Filter_Count = 0;
export const FilterComponent = ({ setOpenFilter }) => {
  const navigate = useNavigate();
  const [deals, setDeals] = useState(false);
  const [homeDelivery, setHomeDelivery] = useState(false);
  Filter_Count = [deals, homeDelivery].filter((v) => v === true).length;

  return (
    <>
      <div className={style.filters}>
        <FilterAltIcon sx={{ fontSize: 30 }} />
        <div className={style.filtercheck} >
        <p>Filters</p>
        <CloseIcon
          sx={{ color: "brown",  fontSize: 30 }}
          onClick={() => {
            _deals = deals;
            _homeDelivery = homeDelivery;
            handleFilter();
            navigate("/Home");
            setOpenFilter(false);

            // navigate("/businessSearch");
          }}
        />
        </div>
      </div>
      <div
        className={style.filters}
        style={{
          display: "flex",
        }}
        onClick={() => {
          setDeals(!deals);
        }}
      >
        <IconComponent
          icon={"localoffer"}
          sx={{
            fontSize: 30, 

            color: "#af002b",
            
          }}
        />
  <div className={style.filtercheck} >
  <p>Deals</p>
  {deals && (
    <Checkbox
      checked={deals}
      onChange={() => setDeals(!deals)}
      style={{
        color: "#af002b",
      }}
    />
  )}
</div>

        
      </div>

      <div
        className={style.filters}
        onClick={() => {
          setHomeDelivery(!homeDelivery);
        }}
      >
        <IconComponent
          icon={"homeDelivery"}
          sx={{ fontSize: [30, 50], color: "brown" }}
        />
          <div className={style.filtercheck} >
        <p>Home delviery</p>
        {homeDelivery && (
          <Checkbox
            checked={homeDelivery}
            onChange={() => setHomeDelivery(!homeDelivery)}
            style={{
        
              color: "#af002b",
            }}
          />
        )}
        </div>
      </div>

      <div
        className={style.filtersheader}
        style={{
          border: "none",
          height: "300px",
        }}
      ></div>
    </>
  );
};
