import React, { useEffect, useState } from "react";
import colors from "../../../assets/colors";
import ReviewCard from "./ReviewCard";
import { imageBaseUrl } from "../../pages/Context/Context";
import { json, useNavigate } from "react-router-dom";

const DisplayReviews = ({ item, type, myReviewId, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {item.content.map((el) => (
        <div
          key={el.id}
          style={{
            margin: "10px",
            borderRadius: "12px",

            backgroundColor:
              myReviewId === el.id ? colors.primary : colors.white,
            borderColor: "#EEEEEE",
          }}
        >
          <ReviewCard item={el} myReviewId={myReviewId} onClick={onClick} />
          <div
            style={{
              padding: "5px",
            }}
          >
            {el.images?.length > 0 && (
              <ReviewImages arr={el.images} type={type} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DisplayReviews;

export const ReviewImages = ({ arr, type }) => {
  const navigate = useNavigate();
  const [reviewImages, setReviewImages] = useState({});
  const [reviewImageLoading, setReviewImageLoading] = useState({});

  useEffect(() => {
    // localStorage.getItem('reviewImage')
    formatImages();
  }, [arr]);

  const formatImages = () => {
    const formatted = {};
    arr.forEach((el) => {
      formatted[el] = `${imageBaseUrl}/reviews/${type}/${el}`;
    });
    // localStorage.setItem('reviewImage',JSON.stringify(formatted))
    setReviewImages(formatted);
  };

  return (
    <div
      style={{
        gap: "0.2rem",
        width: "300px",
        display: "flex",
        overflowX: "auto",
      }}
    >
      {arr.map((el, i) => (
        <div key={i} style={{ margin: 5 }}>
          <div style={{ position: "relative" }}>
            <button
              onClick={() => {
                navigate("/Preview", {
                  state: { src: reviewImages[el] },
                });
              }}
              disabled={reviewImageLoading[el] !== "loaded"}
              style={{
                width: "120px",
                height: "110px",
                backgroundColor: "transparent",
                border: "none",
                cursor:
                  reviewImageLoading[el] === "loaded" ? "pointer" : "default",
              }}
            >
              <img
                src={reviewImages[el]}
                alt={`Review ${i}`}
                style={{
                  width: "120px",
                  height: "110px",
                  borderRadius: 5,
                  display:
                    reviewImageLoading[el] === "loading" ? "none" : "block",
                }}
                onLoad={() => {
                  setReviewImageLoading((prev) => ({
                    ...prev,
                    [el]: "loaded",
                  }));
                }}
                onError={() => {
                  setReviewImageLoading((prev) => ({
                    ...prev,
                    [el]: "error",
                  }));
                }}
              />
              {reviewImageLoading[el] === "loading" && (
                <div
                  style={{
                    width: "120px",
                    height: "110px",
                    borderRadius: 5,
                    backgroundColor: "#EEEEEE",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  Loading...
                </div>
              )}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
