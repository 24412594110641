import { Card } from "@mui/material";
import React, { memo, useContext, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import { CircleIcon, IconComponent } from "../../../../Icons/IconComponent";
import { useNavigate } from "react-router-dom/dist";
import colors from "../../../../assets/colors";
import style from "./BussinessCard.module.css";
import { Context } from "../../Context/Context";
import { handleOpenLink } from "../../Details/Details";
export const actionCall = async (action, baseUrl, sessionId) => {
  try {
    let Response = await fetch(`${baseUrl}/api/search/action`, {
      method: "Post",
      headers: { "Content-Type": "application/json", sessionId },
      body: JSON.stringify(action),
    });
  } catch (err) {}
};
export const viewsCall = async (viewObject, baseUrl, sessionId) => {
  try {
    let Response = await fetch(`${baseUrl}/api/search/view/count`, {
      method: "Post",
      headers: { "Content-Type": "application/json", sessionId },
      body: JSON.stringify(viewObject),
    });
    if (Response.ok) {
      let ResponseJson = await Response.json();
      if (ResponseJson.count) return ResponseJson.count;
    }
  } catch (err) {}
};

const BusinessCard = ({ item }) => {
  const navigate = useNavigate();
  const { baseUrl, sessionId, imageBaseUrl, phoneNumber } = useContext(Context);
  const [logoLoading, setLogoLoading] = useState(false);
  const actionObject = {
    viewerPhone: phoneNumber,
    receiverPhone: item?.phoneNumber,
    viewerType: "customer",
    receiverType: item?.itemType,
  };
  const viewObject = {
    receiverPhone: item.phoneNumber,
    receiverType: item.itemType,
  };
  useEffect(() => {
    if (item?.images) {
      setLogoLoading(true);
    }
  }, []);

  return (
    // <div>
    <Card key={item.id} className={style.businessCard}>
      <div className={style.verticalbox2}>
        {item?.images ? (
          <img
            onLoad={() => {
              setLogoLoading(false);
            }}
            className={style.imageCss}
            src={`${imageBaseUrl}/logo/${item.itemType}/${item.images}`}
            alt={item.title}
            style={{}}
          />
        ) : (
          <IconComponent
            icon={"userEmpty"}
            sx={{
              borderRadius: 5,
              width: "80px",
              height: "100px",
            }}
          />
        )}
        {item.deal && (
          <div className={style.dealsIcon}>
            <IconComponent
              icon={"localoffer"}
              iconfamily="MaterialCommunityIcons"
              sx={{ fontSize: 16 }}
              color={colors.white}
            />
            <p>Deals</p>
          </div>
        )}
      </div>

      <div className={style.descriptionContainer}>
        <div
          className={style.description}
          onClick={() => {
            actionObject.actionType = "view";
            actionCall(actionObject, baseUrl, sessionId);
            navigate(`/businessprofile/${item.itemType}`, {
              state: {
                actionObject: actionObject,
                avgRating: item.avgRating,
                item: item,
              },
            });
          }}
        >
          <p className={style.titlefont}>{item.name}</p>
          {item.avgRating != 0 && <StarRating rating={item.avgRating} />}
          <p className={style.distance}>{item.types}</p>
          <p className={style.distance}>
            {item.city} {(item.distanceInMts / 1000).toFixed(2)} Kms
          </p>
          <p className={style.distance}>
            Views : {item.views?.toLocaleString("en-IN")}
          </p>
        </div>
        <div className={style.iconscontainer}>
          <CircleIcon
            icon={"phone"}
            text={"Call"}
            onClick={() => {
              actionObject.actionType = "call";
              actionCall(actionObject, baseUrl, sessionId);
              handleOpenLink(`tel:${item.phoneNumber}`);
            }}
          />
          <CircleIcon
            icon={"messageNotif"}
            text={"Message"}
            onClick={() => {
              actionObject.actionType = "chat";
              actionCall(actionObject, baseUrl, sessionId);
              handleOpenLink(
                `https://wa.me/91${
                  item?.whatsappNumber
                    ? item.whatsappNumber
                    : actionObject?.receiverPhone
                }`
              );
            }}
          />
          {item.homeDelivery && (
            <CircleIcon icon={"homeDelivery"} text={"Delivery"} />
          )}
          {item.allTimeAvailable && (
            <CircleIcon icon={"twentyFourSeven"} text={"24/7"} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default memo(BusinessCard);

export const StarRating = ({ rating }) => {
  let _rating = rating;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (_rating >= i + 1) {
        stars.push(
          <StarIcon key={i} style={{ color: "gold", fontSize: 11 }} />
        );
      } else if (rating > i) {
        stars.push(
          <StarHalfIcon key={i} style={{ color: "gold", fontSize: 11 }} />
        );
      } else {
        stars.push(
          <StarOutlineIcon key={i} style={{ color: "gray", fontSize: 11 }} />
        );
      }
    }
    return stars;
  };

  return <div>{renderStars()}</div>;
};
