import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../Loading/LoadingScreen";
import { Context } from "../Context/Context";

import styles from "./OtpGenerated.module.css";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import UseOnKeyPress from "../../../utils/UseOnKeyPress";
let isUser;
const OtpGenerated = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const { baseUrl, updateContext, setIsLoggedIn, Ping } = useContext(Context);
  const [seconds, setSeconds] = useState(55);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpRefs = useRef([]);
  const location = useLocation();
  const { headers, phoneNumber } = location.state || {};

  const clearOtp = () => {
    setOtp(Array(6).fill(""));
    otpRefs.current[0].focus();
    setIsOtpComplete(false);
  };

  useEffect(() => {
    const checkUser = async () => {
      try {
        let Response = await fetch(
          `${baseUrl}/api/customer/check/${phoneNumber}`,
          { headers: { phoneNumber: phoneNumber } }
        );
        isUser = await Response.json();
      } catch (error) {}
    };
    checkUser();
  }, []);

  const handleResend = async () => {
    clearOtp();
    setLoading(true);
    try {
      let Response = await fetch(`${baseUrl}/api/session/otp/generate`, {
        headers,
      });
      if (Response.ok) {
        setSeconds(55);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const otpCode = otp.map(Number).join("");
    if (!isOtpComplete) {
      toast.error("Please enter a Correct 6-digit OTP");
      return;
    }

    clearOtp();
    headers.passcode = otpCode;
    setLoading(true);
    try {
      const Response = await fetch(`${baseUrl}/api/session/otp/verify`, {
        headers,
      });

      if (Response.ok) {
        let ResponseJson = await Response.json();

        updateContext({
          phoneNumber: phoneNumber,
          sessionId: `customer-${ResponseJson.token}`,
        });

        if (isUser) {
          //  Ping(baseUrl, `customer-${ResponseJson.token}`);
          sessionStorage.setItem("token", `customer-${ResponseJson.token}`);

          setIsLoggedIn(true);
          // if (isMobile) {
          //   navigate("/businessComponents");
          // } else {
          navigate("/Home");
          // }

          toast.success("sucessfully login home-page");
        } else navigate("/Details");
      } else if (!Response.ok) {
        toast.error("Please enter a Correct 6-digit OTP");
        // navigate("/LoginError", { state: { headers, phoneNumber } });
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error verifying OTP:", error);
      setLoading(false);
    }
  };

  UseOnKeyPress(handleSubmit, "Enter");

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);
  useEffect(() => {
    otpRefs.current[0].focus();
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        setIsOtpComplete(false);
        otpRefs.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d$/.test(value)) {
      return;
    }
    const newOtp = [...otp];

    newOtp[index] = value;

    setOtp(newOtp);

    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
    const isComplete = newOtp.every(
      (digit) => digit.length === 1 && /^\d$/.test(digit)
    );

    setIsOtpComplete(isComplete);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.page}>
          <div className={styles.ContainerOtp}>
            <div className={styles.headers}>
              <h1>Enter your Verification Code</h1>
              <p>
                Sent To +91 {phoneNumber}{" "}
                <button className={styles.editbutton} to="/Edit">
                  Edit
                </button>
              </p>
            </div>

            <div className={styles.inputfield}>
              {otp.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="tel"
                  maxLength={1}
                  value={otp[index]}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(ref) => (otpRefs.current[index] = ref)}
                  autoFocus={index === 0}
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    margin: "0 5px",
                  }}
                />
              ))}
            </div>
            <div className={styles.resend}>
              <p style={{ opacity: seconds > 0 ? 1 : 0.2 }}>
                Resend code in {seconds} s
              </p>
              <button
                className={styles.resendcod}
                disabled={seconds > 0}
                onClick={handleResend}
              >
                Resend code
              </button>
            </div>
            <div className={styles.SudmitBtn}>
              <button
                style={{
                  backgroundColor: isOtpComplete ? "black" : "gray",
                  color: "white",
                  cursor: isOtpComplete ? "pointer" : "not-allowed",
                }}
                type="submit"
                disabled={!isOtpComplete}
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpGenerated;
