import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useWindowWidth } from "@react-hook/window-size";
import { toast } from "react-toastify";

let getUser;

export const deleteLocalImages = async (_uri) => {
  try {
    const isFile = await window.getInfoAsync(_uri, { size: true });
    const isdeleted = await window.deleteAsync(_uri, { idempotent: false });
  } catch (err) {}
};

const makeImageDirectory = async () => {
  try {
    const isDirectory = await window.showDirectoryPicker();

    if (!isDirectory.exists) {
      //  const imageDirectory = await FileSystem.makeDirectoryAsync(
      //     FileSystem.documentDirectory + "Images",
      //     { intermediates: false }
      // );
      const imageDirectory = await isDirectory.getDirectoryHandle("Images", {
        create: true,
      });
    }
  } catch (err) {}
};

export const Ping = async (url, id) => {
  try {
    const lastPing = await AsyncStorage.getItem("ping");
    if (!lastPing) makeImageDirectory();
    let Response = await fetch(`${url}/api/session/ping`, {
      headers: { sessionId: id },
    });
    let ResponseJson = await Response.json();
    if (Response.ok && lastPing) {
      const lastPingJson = await JSON.parse(lastPing);
      const local =
        lastPingJson.updateTime === ResponseJson.lastUpdatedTime ||
        ResponseJson.lastUpdatedTime === null;

      if (!local) {
        await AsyncStorage.setItem(
          "ping",
          JSON.stringify({ updateTime: ResponseJson.lastUpdatedTime })
        );
        getUser();
      }
    } else {
      if (Response.ok) {
        await AsyncStorage.setItem(
          "ping",
          JSON.stringify({ updateTime: ResponseJson.lastUpdatedTime })
        );
      }
      getUser();
    }
  } catch (err) {}
};

export const searchIcons = [
  {
    city: "hyderabad",
    icon: "Groceries",
    image: "groceries.png",
    isDisplay: true,
    priority: 1,
    searchTerm: "groceries",
  },
  {
    city: "hyderabad",
    icon: "Food",
    image: "food.png",
    isDisplay: true,
    priority: 2,
    searchTerm: "restaurant",
  },
  {
    city: "hyderabad",
    icon: "Medicine",
    image: "health.png",
    isDisplay: true,
    priority: 3,
    searchTerm: "pharmacy",
  },
  {
    city: "hyderabad",
    icon: "service1",
    image: "electrical.png",
    isDisplay: true,
    priority: 4,
    searchTerm: "electrician",
  },
  {
    city: "hyderabad",
    icon: "service2",
    image: "carpenter.png",
    isDisplay: true,
    priority: 5,
    searchTerm: "carpenter",
  },
  {
    city: "hyderabad",
    icon: "Hotel",
    image: "hotels.png",
    isDisplay: true,
    priority: 6,
    searchTerm: "hotel",
  },
];

export const baseUrl = "https://www.onestup.com";
// export const baseUrl = "https://test.onestup.com";
export const imageBaseUrl = "https://images.onestup.com";
export const iconImageUrl = "https://cdn.onestup.com/icons/";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const width = useWindowWidth();
  const [context, setContext] = useState({});
  const [onboarded, setOnboarded] = useState(false);
  const [detailsLoaded, setDetailsLoaded] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState();

  const login = (sessionId) => {
    sessionStorage.setItem("token");
    setIsLoggedIn(true);
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
  };
  useEffect(() => {
    if (isLoggedIn ) {
      getUser();
    }
  }, [isLoggedIn, context.sessionId]);

  const getUser = async () => {
    try {
      if (!context.sessionId) {
        toast.error("Session ID not found, logging out");
        logout();
        return;
      }

      const response = await fetch(`${baseUrl}/api/customer`, {
        headers: { sessionId: context.sessionId },
      });

      if (!response.ok) {
        const responseJson = await response.json();
        toast.error("Please login to your account");
        // logout();
        return;
      }

      const userData = await response.json();
      updateContext({ ...userData });
    } catch (error) {
      toast.error("An error occurred while fetching user data");
      // logout();
    }
  };

  const clearContext = () => {
    setContext({});
    storeBasicDetails("clear");
  };

  const updateContext = (newItem) => {
    setContext((prev) => ({ ...prev, ...newItem }));
    storeBasicDetails(newItem);
  };

  const storeBasicDetails = async (newContext) => {
    try {
      if (newContext === "clear") {
        let deleteItem = localStorage.removeItem("basicDetails");
        return;
      }
      let storeItem = localStorage.setItem(
        "basicDetails",
        JSON.stringify(newContext)
      );
      let getstoredItem = localStorage.getItem("basicDetails");
      let Item = await JSON.parse(getstoredItem);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchBasicDetails = async () => {
      try {
        let Response = localStorage.getItem("basicDetails");
        if (Response) {
          let ResponseJson = await JSON.parse(Response);
          updateContext({ ...ResponseJson });
          setDetailsLoaded(true);
        }
      } catch (err) {}
    };
    fetchBasicDetails();
  }, []);

  return (
    <Context.Provider
      value={{
        ...context,
        baseUrl,
        imageBaseUrl,
        iconImageUrl,
        searchIcons,
        updateContext,
        clearContext,
        setIsLoggedIn,
        isLoggedIn,
        onboarded,
        setOnboarded,
        login,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  );
};
