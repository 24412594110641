import React from "react";

import { iconImageUrl } from "../Components/pages/Context/Context";
import style from "./SearchInput.module.css";

const Iconsline = ({ iconList, handleSearch, color }) => {
  return (
    <div className={style.buttontscontainer}>
      <p
        className={style.nearme}
        onClick={() => {
          if (handleSearch) {
            handleSearch("near me", true);
          }
        }}
      >
        Near me
      </p>
      {iconList?.map((el, i) => (
        <button
          key={i}
          style={{
            color,
            border: "none",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "60px",
           
    height: "60px"}}
          onClick={() => {
            if (handleSearch) {
              handleSearch(el.searchTerm, true);
            }
          }}
        >
          <img
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "30px",
            }}
            src={`${iconImageUrl + el.image}`}
            alt="icon"
          />
          <span className={style.iconname}>{el.searchTerm}</span>
        </button>
      ))}
    </div>
  );
};

export default Iconsline;
