import React from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styles from "./RenderPhotos.module.css"

const PreViewImage = () => {
  const { width, height } = useWindowWidth();
  const location = useLocation();
  const { src } = location.state || {};
  const navigate = useNavigate();


  return (
    <div className={styles.preViewImag}>
      <div
        onClick={() => {
          navigate(-1); 
        }}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          columnGap: 10,
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <ChevronLeftIcon sx={{ fontSize: 40, color: "brown" }} />
      </div>
      <div style={{
        width:'800px',
        height:'800px',
        display:"flex",
        justifyContent:'center',
        alignItems:"center"
      }}>
    
        <img
          src={src}
          width={width}
          height={height}
          alt="Preview"
          style={{ objectFit: "contain",  }}
        />
      </div>
    </div>
  );
};

export default PreViewImage;
