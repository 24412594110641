import React, { useContext, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Avatar, Popover, IconButton, Button } from "@mui/material";
import styles from "./EditProfile.module.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useNavigate } from "react-router-dom";
import { Fonts } from "../../../assets/fonts";
import PersonIcon from "@mui/icons-material/Person";
import {
  Context,
  baseUrl,
  deleteLocalImages,
  imageBaseUrl,
} from "../../pages/Context/Context";
import LoadingScreen from "../../pages/Loading/LoadingScreen";
import DetailUpdate from "../../../utils/DetailUpdate/DetailUpdate";
import moment from "moment";

const EditProfile = () => {
  const { name, email, phoneNumber, images, updateContext, sessionId } =
    useContext(Context);
  const navigate = useNavigate();
  const [logoLoading, setLogoLoading] = useState(false);

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (images) {
      setLogo(images);
    }
  }, [images]);

  const goBack = () => {
    navigate(-1);
  };

  const getFilename = (tag, extension) => {
    const date = moment().format("YYYY-MM-DD");
    const time = moment().format("HH-mm-ss");
    return `${tag}-${phoneNumber}-${date}-${time}.${extension}`;
  };

  const addLogo = async (file) => {
    const extension = file.name.split(".").pop();
    const name = getFilename("logo", extension);
    await uploadLogo(file, name);
  };

  const handleLogoChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setLogoLoading(true);
      await addLogo(file);
    }
  };

  const uploadLogo = async (file, name) => {
    const imageData = new FormData();
    imageData.append("image", file);

    try {
      setLogoLoading(true);
      if (logo) {
        try {
          const deleteResponse = await fetch(
            `${baseUrl}/api/img/logo/${logo}`,
            {
              method: "DELETE",
              headers: { sessionId },
            }
          );

          if (deleteResponse.ok) {
            setLogo(null);
            updateContext({ images: null });
            deleteLocalImages(images);
          } else {
            throw new Error("Error deleting existing image");
          }
        } catch (error) {}
      }

      const response = await fetch(`${baseUrl}/api/img/logo/${name}`, {
        method: "POST",
        headers: { sessionId },
        body: imageData,
      });

      if (response.ok) {
        setLogo(name);
        updateContext({ images: name });
        // localStorage.setItem('e')
      } else {
        throw new Error("Error uploading image");
      }
    } catch (error) {
    } finally {
      setLogoLoading(false);
    }
  };

  // const deleteCurrentLogo = async () => {
  //   if (logo) {
  //     try {
  //       setLogoLoading(true);
  //       const response = await fetch(`${baseUrl}/api/img/logo/${"logo-9182370857-2024-06-25-11-38-33.jpg"}`, {
  //         method: "DELETE",
  //         headers: { sessionId },
  //       });

  //       if (response.ok) {
  //         setLogo(null);
  //         updateContext({ images: null });
  //       } else {
  //         console.error("Error deleting current image:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error deleting current image:", error);
  //     } finally {
  //       setLogoLoading(false);
  //     }
  //   }
  // };

  return (
    <div className={styles.boxEditprofile} style={{
      padding:"12px"
    }}>
      <div className={styles.chevronleftIcon}>
        {/* <ChevronLeftIcon
          onClick={goBack}
          sx={{
            display: ["", "none"],
            fontSize: ["35px", "60px"],
          }}
        /> */}
        <p className={styles.edit} style={{ fontFamily: Fonts.dmSans }}>
          Edit Profile
        </p>
      </div>

<div style={{
  display: "flex",
  flexDirection: "column",
  alignItems:"center"
}}>


      <div className={styles.EditProfileborder} >
        {logo ? (
          <>
            {logoLoading && <LoadingScreen />}
            <Avatar
              src={`${imageBaseUrl}/logo/customer/${images}`}
              alt="Logo"
              className={styles.imgestyle}
              onLoadStart={() => setLogoLoading(true)}
              onLoad={() => setLogoLoading(false)}
              onError={() => setLogoLoading(false)}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </>
        ) : (
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          >
            <PersonIcon style={{ fontSize: 60 }} />
          </Avatar>
        )}
      
        <IconButton component="label">
          <AddAPhotoIcon
            sx={{
              fontSize: "2rem",
              marginLeft: "4rem",
              color: "#af002b",
              background: "#e5e5e5",
            }}
          />
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleLogoChange}
          />
        </IconButton>
      </div>
      </div>

      <div className={styles.containertext}>
        <DetailUpdate title="Full Name" text={name} />
        <DetailUpdate title="Email" text={email} />
        <DetailUpdate title="Phone Number" text={phoneNumber} />
      </div>
    </div>
  );
};

export default EditProfile;
