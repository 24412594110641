import React, { useContext, useState } from "react";
import styles from "./MobileNavbarScreen.module.css";
import { Link } from "react-router-dom";
import { Avatar, Box, Button, Divider, Drawer } from "@mui/material";

import * as FaIcons from "react-icons/fa";

import { IconComponent } from "../../../../Icons/IconComponent";
import { SidebarData } from "../Tablet/TabletScreenNavBar";
import { Context, imageBaseUrl } from "../../../pages/Context/Context";
const MobileNavBar = () => {
  const{logout,images} =useContext(Context);
  const [openMenu, setOpenMenu] = useState(false);

  const [buttonValue, setButtonValue] = useState(0);

  const showSidebar = () => setOpenMenu(!openMenu);
  return (
    <div>
      <nav>
        <div>
          <div className={styles.mobilemenucontainer}>
            <FaIcons.FaBars
              style={{
                fontSize: "20px",
                margin: "10px",
                color: "#fff",
              }}
              onClick={showSidebar}
            />

            <div>
              <IconComponent
                icon={"appName"}
                sx={{
                  fontSize: "160px",
                  color: "#ffffff",
                  height: "48px",
                }}
              />
            </div>
            <div>
              <Link to="/Customerprofile">
                <Button label="Profile " value={buttonValue}>
                  <Avatar
                    src={`${imageBaseUrl}/logo/customer/${images}`}
                    sx={{
                      height: "40Px",
                      width: "35px",
                      color: "white",
                      background: "#AF002B",
                      paddingBottom: "2px",
                    }}
                  />
                </Button>
              </Link>
            </div>
          </div>
          {/* <div className={styles.mobileSearchInput}>
            <SearchInput />
          </div> */}
          <>
            <Drawer
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              anchor="left"
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => setOpenMenu(false)}
                onKeyDown={() => setOpenMenu(false)}
              >
                <SidebarData />
                <button className={styles.logoutButton} onClick={()=>{
                  logout();
                }}>Log out</button>
                <Divider />
              </Box>
            </Drawer>
          </>
        </div>
      </nav>

      {/* <div className={styles.locationmobile}>
        <IconComponent
          role="img"
          aria-label="Location Pin"
          icon="locationPin"
          sx={{ fontSize: 25, color: "#af002b" }}
        />

        <p>
        <LocationComponent />
        </p>
      </div> */}

      {/* <Footer /> */}
    </div>
  );
};

export default MobileNavBar;
