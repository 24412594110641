import React from "react";
import style from "./Timings.module.css";

const TimingSheet = ({ businessHours }) => {
  const setTime = (t) => {
    const _t =
      t >= 1300
        ? (t - 1200 + " PM").padStart(7, 0)
        : (t + " AM").padStart(7, 0);
    return _t.slice(0, 2) + ":" + _t.slice(2);
  };

  const FormatTime = ({ day, from, to }) => {
    if ((from == 0 || from == null) && (to == 0 || to == null))
      return (
        <div className={style.timingcontainer}>
           <p className={style.text}>{day} </p>
           <p className={style.text}>Closed</p>
        </div>
      );
    else
      return (
        <div className={style.timingcontainer}>
          <p className={style.text}>{day} </p>
          <p className={style.text}>{setTime(from) + " - " + setTime(to)}</p>

        </div>
      );
  };

return(
  <>
   <FormatTime
        day="Sunday"
        from={businessHours.sundayFrom}
        to={businessHours.sundayTo}
      />
      <FormatTime
        day="Monday"
        from={businessHours.mondayFrom}
        to={businessHours.mondayTo}
      />
      <FormatTime
        day="Tuesday"
        from={businessHours.tuesdayFrom}
        to={businessHours.tuesdayTo}
      />
      <FormatTime
        day="Wednesday"
        from={businessHours.wednesdayFrom}
        to={businessHours.wednesdayTo}
      />
      <FormatTime
        day="Thursday"
        from={businessHours.thursdayFrom}
        to={businessHours.thursdayTo}
      />
      <FormatTime
        day="Friday"
        from={businessHours.fridayFrom}
        to={businessHours.fridayTo}
      />
      <FormatTime
        day="Saturday"
        from={businessHours.saturdayFrom}
        to={businessHours.saturdayTo}
      />
  </>
)}

//   return (
//     <div className="timing-container">
//       <p>Timings</p>
//       <ul style={{ padding: "0" }}>
//         {days.map((day) => (
//           <li key={day.value} className="li-days">
//             <strong>{day.label}</strong>
//             <strong style={{ marginRight: 0 }}>11:00 AM - 11:30 PM</strong>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

export default TimingSheet;
