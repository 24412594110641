export const Fonts = {
    interSemibold: "Inter_600SemiBold",
    interMedium: "Inter_500Medium",
    interRegular: "Inter_400Regular",
    dmSansBold: "DMSans_700Bold",
    dmSansRegular: "DMSans_400Regular",
    dmSansMedium: "DMSans_500Medium",
  };
  
  export const Fontsize = {
    largeTitle: 34,
    smallTitle: 32,
    title1: 28,
    title3: 20,
    title2: 22,
    headline: 18,
    body: 17,
    callout: 16,
    subheading: 15,
    menuItem: 14,
    footnote: 13,
    caption1: 12,
    caption2: 11,
  };
  