import React, { useContext, useEffect, useState } from "react";
import styles from "./BusinessProfile.module.css";
import CustomButton from "../CustomButton/CustomButton";
import { Avatar, CircularProgress } from "@mui/material";
import { IconComponent } from "../../../Icons/IconComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonIcon from "@mui/icons-material/Person";
import { Fonts } from "../../../assets/fonts";
import RenderPhotos from "./PhotosBusiness/RenderPhotos";
import TimingSheet from "../Timings/TimingSheet";
import { Context, baseUrl } from "../../pages/Context/Context";
import { StarRating } from "../../pages/Cards/BussinessCards/BusinessCard";
import { handleOpenLink } from "../../pages/Details/Details";
import DisplayReviews from "../Reviews/DisplayReviews";
import { LocalActivity } from "@mui/icons-material";
import LoadingScreen from "../../pages/Loading/LoadingScreen";
import colors from "../../../assets/colors";
import { toast } from "react-toastify";
let _myReview = {};
export let _actionObject = {};
let _avgRating = null;
export let _views = null;
export let _item;
const BusinessProfile = () => {
  const { imageBaseUrl, sessionId, phoneNumber } = useContext(Context);
  const location = useLocation();
  const { actionObject, newReview, item, url } = location.state || {};

  const [user, setUser] = useState({});
  const [reviews, setReviews] = useState({});
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [photos, setPhotos] = useState({});
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate("/home", { state: { from: "BusinessProfile" } });
  };

  useEffect(() => {
    _myReview = {};
    if (url) {
      getProfileDetails();
    } else {
      _avgRating = item?.avgRating;
      _views = item?.views;
      _actionObject = actionObject;
      _item = item;
      getDetails();
      getReviews(0, "most_recent");
      // checkFavourite();
    }

    return () => {
      _myReview = {};
    };
  }, [url, sessionId]);
  useEffect(() => {
    if (newReview) {
      _myReview = newReview;
      updateMyReviews();
    }
  }, [newReview]);

  const getProfileDetails = async () => {
    if (!sessionId) return;
    try {
      setLoading(true);
      const Response = await fetch(`${baseUrl}/api/search/profile/${url}`, {
        headers: { sessionId },
      });
      const ResponseJson = await Response.json();
      if (Response.ok) {
        _actionObject.receiverType = ResponseJson.profileType;
        _actionObject.receiverPhone = ResponseJson.phoneNumber;
        _actionObject.viewerType = "customer";
        _actionObject.viewerPhone = phoneNumber;
        getReviews(0, "most_recent");
        getAvgRating();
        getViews();
        // checkFavourite();

        let { images, ...userData } = ResponseJson;

        setUser(userData);
        if (images.length > 0) formatImages(images);
      }
      setLoading(false);
    } catch (error) {}
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      let Response = await fetch(`${baseUrl}/api/search/profile`, {
        method: "Post",
        headers: {
          sessionId,
          typeEnum: _actionObject?.receiverType,
          phoneNumber: _actionObject?.receiverPhone,
        },
      });

      let { images, ..._user } = await Response.json();

      setUser(_user);
      if (images.length > 0) formatImages(images);
      setLoading(false);
    } catch (err) {}
  };
  const getAvgRating = async () => {
    try {
      const avgArray = {
        input: [
          {
            number: _actionObject?.receiverPhone,
            receiverType: _actionObject?.receiverType,
          },
        ],
      };
      const Response = await fetch(`${baseUrl}/api/reviews/avgRating`, {
        method: "Post",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(avgArray),
      });
      const ResponseJson = await Response.json();
      _avgRating = ResponseJson[0]?.avgRating;
      setUser((prev) => ({ ...prev }));
    } catch (err) {}
  };
  const getViews = async () => {
    try {
      const viewBody = {
        receiverPhone: _actionObject?.receiverPhone,
        receiverType: _actionObject?.receiverType,
      };
      const Response = await fetch(`${baseUrl}/api/search/view/count`, {
        method: "Post",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(viewBody),
      });
      const ResponseJson = await Response.json();
      _views = ResponseJson.count;
      setUser((prev) => ({ ...prev }));
    } catch (err) {}
  };

  // const checkFavourite = async () => {
  //   try {
  //     const favObj = {
  //       bookmarkType: _actionObject?.receiverType,
  //       bookmarkNumber: _actionObject?.receiverPhone,
  //     };
  //     const Response = await fetch(`${baseUrl}/api/favorite/check`, {
  //       method: "Post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         sessionId,
  //       },
  //       body: JSON.stringify(favObj),
  //     });
  //     const ResponseJson = await Response.json();
  //     console.log(ResponseJson, "ResponseJson");
  //     setFavourite(ResponseJson.condition);
  //   } catch (err) {}
  // };

  const formatImages = (arr) => {
    const receiver = _actionObject?.receiverType || "defaultReceiver";
    const formatArray = arr.map((el) => {
      const imageType = el.includes("logo") ? "logo" : "image";
      const src = `${imageBaseUrl}/${imageType}/${receiver}/${el}`;
      return [el, src];
    });

    const imageObject = arrayToObject(formatArray);
    setPhotos(imageObject);
  };

  const arrayToObject = (array) => {
    const object = {};
    const arr = [];
    for (let pair of array) {
      const [key, value] = pair;
      if (key.includes("logo")) {
        object["logo"] = { id: key, src: value };
      } else {
        arr.push({ id: key, src: value });
      }
    }
    if (arr.length > 0) {
      object["photos"] = [...arr];
    }
    return object;
  };

  const openMaps = () => {
    const { latitude, longitude } = user.loc || {};
    if (latitude && longitude) {
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      handleOpenLink(mapsUrl, "_blank");
    }
  };

  const getMyReview = async () => {
    try {
      const Response = await fetch(`${baseUrl}/api/reviews/find`, {
        headers: {
          sessionId,
          receiverType: _actionObject.receiverType,
          receiverPhone: _actionObject.receiverPhone,
        },
      });
      if (Response.ok) {
        const ResponseJson = await Response.json();
        return ResponseJson;
      }
    } catch (err) {}
  };
  const getReviews = async (x, sort) => {
    try {
      setReviewsLoading(true);

      if (!_myReview?.id) {
        _myReview = await getMyReview();
      }

      const response = await fetch(
        `${baseUrl}/api/reviews?size=3&pageNumber=${x}&sort=${sort}`,
        {
          headers: {
            sessionId,
            type: _actionObject?.receiverType,
            review_ph_no: _actionObject?.receiverPhone,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch reviews");
      }

      const responseJson = await response.json();

      if (response.ok) {
        const { content, countOfPages } = responseJson;
        setReviews((prev) => {
          const newContent = prev.content
            ? [_myReview, ...prev.content, ...content]
            : [_myReview, ...content];

          return {
            ...responseJson,
            content: newContent.filter((obj, index, arr) => {
              if (!obj.id) return false;
              return index === arr.findIndex((o) => obj.id === o.id);
            }),

            countOfPages,
          };
        });
      } else {
        toast.error("Could not load reviews. Please try again");
      }
    } catch (error) {
    } finally {
      setReviewsLoading(false);
    }
  };

  const updateMyReviews = async () => {
    setReviews((prev) => {
      const newContent = _myReview
        ? [_myReview, ...(prev?.content || [])]
        : prev?.content || [];

      const uniqueContent = newContent.filter((obj, index, arr) => {
        if (!obj?.id) return true;
        return index === arr.findIndex((o) => obj.id === o.id);
      });

      return {
        ...prev,
        content: uniqueContent,
      };
    });
  };
  const _writeReview = () => {
    navigate("/writeReview", {
      state: {
        actionObject,
        _avgRating,
        _myReview,
      },
    });
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.page}>
          <div className={styles.cover}>
            <div
              onClick={goBack}
              style={{
                cursor: "pointer",
                width: "100%",
                padding: "10px",
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: [40, 30], color: "brown" }} />
            </div>
            <div className={styles.Profileborder}>
              {photos && photos.logo && photos.logo.src ? (
                <>
                  {logoLoading && (
                    <CircularProgress
                      style={{ height: 120, backgroundColor: "#EEEEEE" }}
                      size="large"
                    />
                  )}
                  <div className={styles.profile}>
                    <button
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        objectFit: "cover",
                        cursor: "pointer",
                        border: "none",
                      }}
                      onClick={() => {
                        navigate("/Preview", {
                          state: { src: photos?.logo.src },
                        });
                      }}
                    >
                      <Avatar
                        src={photos?.logo.src}
                        alt="Profile"
                        style={{
                          display: logoLoading ? "none" : "flex",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onLoadStart={() => {
                          setLogoLoading(true);
                        }}
                        onLoadEnd={() => {
                          setLogoLoading(false);
                        }}
                        onError={({ nativeEvent: { error } }) => {
                          setLogoLoading(false);
                        }}
                      />
                    </button>
                  </div>
                </>
              ) : (
                <Avatar
                  sx={{
                    width: ["100%", "100%"],
                    height: ["100%", "100%"],
                    borderRadius: "50%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                >
                  <PersonIcon style={{ fontSize: 60 }} />
                </Avatar>
              )}
            </div>
            <div className={styles.RatingReviewNameContainer}>
              <p className={styles.profileNames}>{user.name}</p>

              {_avgRating > 0 && (
                <p>
                  Rating <StarRating rating={_avgRating} fontSize={15} />
                </p>
              )}
              {reviews?.total > 0 && <p>{reviews?.total} Reviews</p>}

              {/* <div style={{
                display: "flex",
               }}>
               <IconComponent
                icon={"phone"}
                sx={{ fontSize: 31, color: "#af002b" }}
                
              />
               <p>{user.phoneNumber}</p>
               </div> */}
            </div>

            <div className={styles.prINFO}>
              {user[
                _actionObject.receiverType === "business"
                  ? "businessSectors"
                  : "services"
              ]?.map((el, i, arr) => {
                return (
                  <p key={i} className={styles.typeservice}>
                    {el.typeName}
                    {i < arr.length - 1 ? ", " : "."}
                  </p>
                );
              })}
            </div>

            <div className={styles.CustomButton}>
              <CustomButton actionObject={actionObject} user={user} />
            </div>
            {photos.photos && <RenderPhotos data={photos.photos} />}
            {user.description && (
              <div className={styles.descriptionbox}>
                <p className={styles.description}>Description </p>
                <p
                  className={styles.p}
                  style={{
                    fontFamily: Fonts.dmSansRegular,
                  }}
                >
                  {user.description}
                </p>
              </div>
            )}

            {_actionObject.receiverType === "business" && (
              <>
                <div className={styles.businesslocation}>
                  <div className={styles.loctionpinbox}>
                    <IconComponent
                      icon={"locationPin"}
                      sx={{
                        fontSize: "2rem",
                        color: "#ffffff",
                      }}
                      onClick={() => {
                        if (
                          user.loc &&
                          _actionObject.receiverType === "business"
                        )
                          openMaps();
                      }}
                    />
                  </div>
                  <p className={styles.addressloction}>
                    {[
                      user?.area1,
                      user?.area2,
                      user?.city,
                      user?.state,
                      user?.country,
                      user?.pin,
                    ]
                      .filter(Boolean)
                      .join(" ,")}
                  </p>
                </div>
              </>
            )}

            <div className={styles.timmingsbprofile}>
              {_actionObject.receiverType === "business" &&
                user.businessHours && (
                  <>
                    <p className={styles.heading}> Timing</p>
                    <div style={{ padding: "1rem" }}>
                      <TimingSheet businessHours={user?.businessHours} />
                    </div>
                  </>
                )}
            </div>

            <p className={styles.heading}>Reviews</p>
            {!_myReview?.id && (
              <div className={styles.reviews}>
                <div className={styles.ReviewinputBar} onClick={_writeReview}>
                  <PersonIcon
                    style={{ fontSize: 25 }}
                    className="person-icon"
                  />
                  <p>Write a Review</p>
                </div>
              </div>
            )}
            <div style={{}}>
              {reviews?.content?.length > 0 && (
                <DisplayReviews
                  item={reviews}
                  type={_actionObject?.receiverType}
                  myReviewId={_myReview?.id}
                  onClick={_writeReview}
                />
              )}

              <div className={styles.footer}>
                {reviewsLoading ? (
                  <LocalActivity
                    color={colors.primary}
                    style={{ margin: 15 }}
                  />
                ) : (
                  <>
                    {reviews.countOfPages > reviews.pageNumber + 1 && (
                      <button
                        onClick={() => {
                          if (reviews.countOfPages > reviews.pageNumber + 1)
                            getReviews(reviews.pageNumber + 1, "most_recent");
                        }}
                      >
                        Show More
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessProfile;
