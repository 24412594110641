import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingScreen from "../../pages/Loading/LoadingScreen";
import { Fonts, Fontsize } from "../../../assets/fonts";
import colors from "../../../assets/colors";
import { Context } from "../../pages/Context/Context";
import { useNavigate } from "react-router-dom";
import { StarRating } from "../../pages/Cards/BussinessCards/BusinessCard";
import { IconComponent } from "../../../Icons/IconComponent";

const ReviewCard = ({ item, myReviewId, onClick }) => {
  const navigate = useNavigate();
  const { imageBaseUrl } = useContext(Context);
  const [reviewerImagesLoading, setReviewerImagesLoading] = useState({});
  const myReview = myReviewId === item.id;
  const timePassed = item.durationInMinutes / 60;
  let ago;
  if (item.durationInMinutes < 60) {
    ago = item.durationInMinutes + " minutes ago";
  } else if (timePassed < 24) {
    ago = Math.ceil(timePassed) + " hours ago";
  } else if (timePassed < 48) {
    ago = "yesterday";
  } else if (timePassed / 24 < 30) {
    ago = Math.ceil(timePassed / 24) + " days ago";
  } else if (timePassed / 24 / 30 < 12) {
    ago = Math.ceil(timePassed / 24 / 30) + " months ago";
  } else {
    ago = Math.ceil(timePassed / 24 / 30 / 12) + " years ago";
  }

  return (
    <div
      style={{
        height: "auto",
        width: "300px",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      {item.reviewerImage ? (
        <div style={{ position: "relative" }}>
          {reviewerImagesLoading[item.reviewerImage] === "loading" && (
            <div style={{ position: "absolute", top: 0, left: 0 }}>
              <LoadingScreen
                style={{
                  width: 73,
                  height: 73,
                  borderRadius: 36.5,
                  backgroundColor: "#EEEEEE",
                }}
                size="small"
              />
            </div>
          )}
          <img
            src={`${imageBaseUrl}/logo/${item.reviewerType}/${item.reviewerImage}`}
            alt="Reviewer"
            style={{
              width: 73,
              height: 73,
              borderRadius: "50%",
              border: "2px solid white",
              display:
                reviewerImagesLoading[item.reviewerImage] === "loading"
                  ? "none"
                  : "block",
            }}
            onLoadStart={() => {
              setReviewerImagesLoading((prev) => ({
                ...prev,
                [item.reviewerImage]: "loading",
              }));
            }}
            onLoad={() => {
              setReviewerImagesLoading((prev) => ({
                ...prev,
                [item.reviewerImage]: "loaded",
              }));
            }}
            onError={() => {
              setReviewerImagesLoading((prev) => ({
                ...prev,
                [item.reviewerImage]: "error",
              }));
            }}
          />
        </div>
      ) : (
        <IconComponent
          icon={"userEmpty"}
          size={48}
          sx={{ width: 63, height: 63, borderRadius: "50%" }}
          iconFamily={"Feather"}
          backgroundColor={colors.lightGrey}
        />
      )}

      <div style={{ flex: 1, marginLeft: "10px" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: myReview ? colors.white : "#0E1934",
              fontFamily: Fonts.dmSansBold,
              fontSize: Fontsize.callout,
            }}
          >
            {item.reviewerName}
          </p>
          {myReview && (
            <p
              style={{
                fontFamily: Fonts.dmSansRegular,
                fontSize: Fontsize.caption1,
                color: colors.white,
                cursor: "pointer",
              }}
              onClick={() => {
                onClick();
              }}
            >
              Edit
            </p>
          )}
        </div>
        <StarRating rating={item.rating} />
        {item.durationInMinutes && (
          <p
            style={{
              fontFamily: Fonts.dmSansRegular,
              fontSize: 10,
              color: myReview ? colors.white : "#0E1934",
            }}
          >
            {ago}
          </p>
        )}
        <p
          style={{
            fontFamily: Fonts.interRegular,
            fontSize: Fontsize.caption1,
            color: myReview ? colors.white : "#0E1934",
          }}
        >
          {item.reviewText}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
