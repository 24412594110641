import React, { useContext, useState } from "react";
import styles from "./LoginError.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LoadingScreen from "../Loading/LoadingScreen";
import { Context } from "../Context/Context";
import { toast } from "react-toastify";

const LoginError = () => {
  const { baseUrl } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const { headers, phoneNumber } = location.state || {};
  const [mobileNumber, setMobileNumber] = useState(phoneNumber);
  const [validMobileNumber, setValidMobileNumber] = useState(true);
  const [loading, setLoading] = useState(false);

  const checkMobileNumber = (event) => {
    const text = event.target.value;
    setMobileNumber(text);
    text.length === 10
      ? setValidMobileNumber(true)
      : setValidMobileNumber(false);
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      if (mobileNumber !== phoneNumber) {
        headers.ph_no = mobileNumber;
      }
      const response = await fetch(`${baseUrl}/api/session/otp/generate`, {
        headers,
      });
      if (response.ok) {
        toast.success("Mobile OTP successfully Send!");
        navigate("/OtpVerification", {
          state: { headers, phoneNumber: mobileNumber },
        });
      } else {
        const rejectedResponse = await response.json();
        toast.error(rejectedResponse.msg);
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.containerError}>
          <div className={styles.loginErrorCover}>
            <div className={styles.InputText}>
              <input
                type="text"
                value={mobileNumber}
                maxLength={10}
                placeholder="Please enter your mobile number"
                onChange={checkMobileNumber}
              />
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyItems: "baseline",
                  padding: 5,
                }}
              >
                <InfoRoundedIcon style={{ color: "#DA4A4A" }} />
                <p
                  style={{
                    padding: 4,
                    color: "#DA4A4A",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Please check your number
                </p>
              </label>
            </div>

            <div className={styles.GenerateOtp}>
              <button
                type="submit"
                onClick={getOtp}
                disabled={!validMobileNumber}
              >
                Generate OTP
              </button>
            </div>
            <div>
              <div className={styles.googlebtn}>
                <button type="submit">
                  <h2 style={{ paddingRight: 18 }}>G</h2>
                  <p>Continue with Google</p>
                </button>
              </div>

              <div className={styles.Guestcreate}>
                <Link to="/OtpVarification" style={{ textDecoration: "none" }}>
                  <button type="submit">Continue as Guest</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginError;
