import React, { useContext } from "react";
import style from "./SearchInput.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { Context } from "../Components/pages/Context/Context";
const SearchInput = ({ general }) => {
  const { search } = useContext(Context);
  const navigate = useNavigate();

  const handleOpen = () => {
    navigate("/businessSearch");
  };

  return (
    <>
      <div className={style.Searchcontainer}>
        <button
          className={style.searchbox}
          style={{ paddingLeft: general ? 8 : 16 }}
          onClick={handleOpen}
        >
          {
            general && null
            // <IconComponent
            //   name="logo"
            //   iconFamily="IcoMoon"
            //   left={-1}
            //   iconStyle={{ left: -1 }}
            //   sx={{ color: "#AF002B" }}
            //   size={24}
            // />
          }

          <label style={{ flex: 1, opacity: 0.4 }}>
            {search || "pharmacy, plumber, etc..."}
          </label>
          <SearchIcon
            icon="search"
            iconfamily="IcoMoon"
            size={20}
            sx={{ fontSize: [20, 20], color: "#AF002B", marginLeft: 1 }}
          />
        </button>
      </div>
    </>
  );
};

export default SearchInput;
