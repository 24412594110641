import React from "react";
import style from "../LocationModal/LocationModal.module.css";
import { IconComponent } from "../../Icons/IconComponent";

const LocationModal = React.forwardRef(
  ({ title, text, cancelButton, allowButton, onCancel, onAllow }, ref) => {
    return (
      // <div className={style.container}>
      <div className={style.modal} ref={ref} tabIndex={-1}>
        <IconComponent
          role="img"
          aria-label="Location Pin"
          icon="locationPin"
          sx={{ fontSize: 25, color: "#af002b", alignSelf: "center" }}
        />
        <h2 className="title">{title}</h2>
        <p className="title">{text}</p>
        <div
          className={style.buttonsContainer}
          // style={{
          //   display:"flex",
          //   justifyContent: "space-around",
          //   width: "100%",
          // }}
        >
          <button
            type="text"
            className={style.buttons}
            // style={{
            //   color: "#0373F0",
            // }}
            onClick={() => {
              onCancel();
            }}
          >
            {cancelButton}
          </button>
          <button
            type="text"
            className={style.buttons}
            // style={{
            //   color: "#0373F0",
            // }}
            onClick={() => {
              onAllow();
            }}
          >
            {allowButton}
          </button>
        </div>
      </div>
      // </div>
    );
  }
);

export default LocationModal;
