import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RenderPhotos.module.css";
import { Button } from "@mui/material";
import { useWindowWidth } from "@react-hook/window-size";

const RenderPhotos = ({ data = [] }) => {
  const [loading, setLoading] = useState({});
  const navigate = useNavigate();
  const width = useWindowWidth();
  const [newData, setNewData] = useState([...data]);
 

  


  return (
    <div className={styles.containerrender}>
      <div className={styles.renderphotosbox}>
        <p>PHOTOS</p>
        <button onClick={() => navigate('/images',{ state:{arr: data }})}>View All</button>

      </div>

      <div className={styles.businessimg}>
        {newData.map((item, index) => (
          <img
            key={index}
            src={item.src} // Changed 'source' to 'src'
            alt=""
            className={styles.imagelist}
            style={{
              display: loading[item.id] === "loading" ? "none" : "flex",
            }}
            onLoadStart={() => {
              setLoading((prev) => ({ ...prev, [item.id]: "loading" }));
            }}
            onLoadEnd={() => {
              setLoading((prev) => ({ ...prev, [item.id]: "loaded" }));
            }}
            onError={({ nativeEvent: { error } }) => {
              setLoading((prev) => ({ ...prev, [item.id]: "error" }));
            }}
            onClick={() => {
              navigate("/Preview", { state: { src: item.src } });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RenderPhotos;
