import React, { useContext, useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import colors from "../../../assets/colors";
import { Context } from "../Context/Context";
import styles from "./Details.module.css";
import { useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
export const handleOpenLink = (url) => {
  window.open(url, "_blank");
};
const Details = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    name: "",
    notify: true,
    phoneNumber: "",
  });

  const [isChecked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const {
    baseUrl,
    phoneNumber,
    updateContext,
    sessionId,
    setIsLoggedIn,
    clearContext,
  } = useContext(Context);

  useEffect(() => {
    setUser((prevUser) => ({ ...prevUser, phoneNumber: phoneNumber }));
  }, [phoneNumber]);

  useEffect(() => {
    const backAction = () => {
      if (!navigate.isFocused()) return false;
      alert(
        "Exit App!",
        "Are you sure you want to exit the App?\n All your progress will be lost.",
        [
          {
            text: "No",
            onPress: () => null,
            style: "cancel",
          },
          { text: "YES", onPress: () => exit() },
        ]
      );
      return true;
    };

    const exit = () => {
      clearContext();
      navigate.goBack();
      window.exitApp();
    };

    window.addEventListener("beforeunload", backAction);

    return () => window.removeEventListener("beforeunload", backAction);
  }, []);

  const handleChange = (text, item) => {
    setUser((prevUser) => ({ ...prevUser, [item]: text }));
  };

  const handleError = (error, input) => {
    toast.error(error, input);
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const validate = () => {
    let isValid = true;
    if (!user.name) {
      handleError("Please input name", "name");
      isValid = false;
    }
    if (user.email && !user.email.match(/\S+@\S+\.\S+/)) {
      handleError(
        "Please input a valid email such as 'example@something.com'",
        "email"
      );
      isValid = false;
    }
    if (isValid) {
      postCustomer();
    }
  };

  const postCustomer = async () => {
    try {
      let Response = await fetch(`${baseUrl}/api/customer`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(user),
      });

      if (Response.ok) {
        updateContext({
          ...user,
        });
        await AsyncStorage.setItem("isLoggedIn", "true");
        setIsLoggedIn(true);
        navigate("/Home");
        toast.success("sucessfully login home-page");
      } else {
        const errorData = await Response.json();

        throw new Error(
          errorData.message ||
            "User details could not be added. Please try again."
        );
      }
    } catch (err) {
      toast.error(
        err.message || "User details could not be added. Please try again."
      );
    }
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.cover}>
          <div className={styles.inputtype}>
            <input
              type="text"
              placeholder="Enter your name"
              value={user.name}
              onFocus={() => handleError(null, "name")}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
            {errors.name && (
              <p style={{ color: colors.warning }}>{errors.name}</p>
            )}
          </div>
          <div className={styles.phonenumber}>
            <p>{phoneNumber}</p>
          </div>
          <div className={styles.inputtype}>
            <input
              type="text"
              placeholder="Enter your email address"
              value={user.email}
              onFocus={() => handleError(null, "email")}
              onChange={(e) => handleChange(e.target.value, "email")}
            />
            {errors.email && (
              <p style={{ color: colors.warning }}>{errors.email}</p>
            )}
          </div>
          <div>
            <Checkbox
              checked={isChecked}
              onChange={(e) => {
                setChecked(e.target.checked);
                handleChange(e.target.checked, "notify");
              }}
              sx={{ color: isChecked ? colors.primary : colors.black }}
            />
            <span
              style={{
                alignItems: "center",
                color: colors.black,
              }}
            >
              Allow emails and notifications
            </span>
          </div>
          <div className={styles.buttonterms}>
            <button
              style={{
                height: 22,
                width: 170,
                paddingRight: 0,
                margin: "1rem",
              }}
              onClick={() => {
                handleOpenLink(
                  "https://cdn.onestup.com/Customer%20Terms%20&%20Conditions.html"
                );
              }}
            >
              <InfoOutlined style={{ fontSize: 16, color: colors.primary }} />
              <span style={{ paddingLeft: 5, fontSize: 12 }}>
                Terms and Conditions
              </span>
            </button>
            <button className={styles.getstarted} onClick={validate}>
              Get started
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
