import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import "./BusinessComponents.css";


import SearchInput from "../../../SearchInput/SearchInput";
import {  Context} from "../Context/Context";


import MobileNavBar from "../../Navbar/Screens/Mobile/MobileNavBar";

import BusinessSearch, {
  HomeIcons,
} from "../../../SearchInput/BusinessSearch/BusinessSearch";

function BusinessComponents() {
  const{iconSearch} = useContext(Context)
  
  

  return (
    <>
      <div >
        <nav>
          <MobileNavBar />
          <div style={{ marginLeft: "20px" }}>
            <SearchInput general={true} />
          </div>
        </nav>
      

      <div>
      {iconSearch.length > 0 && 
        <HomeIcons />
  }
      </div>
      </div>
    </>
  );
}

export default BusinessComponents;
