import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Drawer } from "@mui/material";
import style from "./TabletScreenNavBar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import * as FaIcons from "react-icons/fa";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HttpsIcon from "@mui/icons-material/Https";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { IconComponent } from "../../../../Icons/IconComponent";
import EditProfile from "../../../ProfilePage/EditProfile/EditProfile";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Context, imageBaseUrl } from "../../../pages/Context/Context";
import { useContext } from "react";
import { FilterComponent } from "../../../../SearchInput/BusinessSearch/BusinessSearch";
import { handleOpenLink } from "../../../pages/Details/Details";
import LocationComponent, { useLoc } from "../../../../utils/LocationComponent";
import { _item } from "../../../ProfilePage/BusinessProfile/BusinessProfile";
import MediaQuery from "react-responsive";

export const handleHomeClick = () => {
  localStorage.removeItem("finalResults");
  window.location.reload();
};
const TabletScreenNavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const { isLoggedIn, login, logout, images } = useContext(Context);

  const { city, address } = useLoc();
  //console.log("TabletScreenNavBar city:", city, "address:", address, images);

  const showSidebar = () => setOpenMenu(!openMenu);

  return (
    <>
      <nav>
        <div className={style.tabletmenuappprofile}>
          <FaIcons.FaBars className={style.menubars} onClick={showSidebar} />
          {/* <div className={style.navheader}> */}
          <div className={style.navtitle}>
            <IconComponent
              icon={"appName"}
              sx={{
                // marginTop: ["15px", "2px"],
                fontSize: "170px",
                color: "#ffffff",
                height: "48px",
              }}
            />
          </div>
          {/* </div> */}
          <div className={style.locationbox}>
            <IconComponent
              role="img"
              aria-label="Location Pin"
              icon="locationPin"
              sx={{ fontSize: 25, color: "#af002b" }}
            />

            <p>
              {/* <LocationComponent /> */}
              {city}
            </p>
          </div>
          {/* <div className={style.tabIconsSearchBox}> */}
          <MediaQuery minWidth={768}>
            <div className={style.tabcontext}>
              <NavLink
                to="/Home"
                onClick={handleHomeClick}
                className={style.tabcon}
              >
                <HomeIcon sx={{ fontSize: 25 }} />
                <p>Home</p>
              </NavLink>

              <NavLink to="/favorites">
                <FavoriteBorderIcon sx={{ fontSize: 26, marginLeft: 1 }} />
                <p>Favourites</p>
              </NavLink>

              <NavLink to="" onClick={() => setOpenFilter(true)}>
                <FilterAltIcon sx={{ fontSize: 27 }} />
                <p>Filters</p>
              </NavLink>
              {/* <Link to="/Chat">
              <ChatIcon sx={{ fontSize: 23 }} />
              <p>Chat</p>
            </Link> */}
            </div>
          </MediaQuery>
          {/* <div className={style.tabletSearchInput}>
              <SearchInput />
            </div> */}
          {/* </div> */}

          <div className={style.profileSignup}>
            <Button onClick={() => setOpenProfile(true)} label="Profile">
              <Avatar
                src={`${imageBaseUrl}/logo/customer/${images}`}
                className="AccountIcon"
                sx={{
                  height: "40px",
                  width: "40px",
                  color: "white",
                  background: "#AF002B",
                }}
              />
            </Button>
            {isLoggedIn ? (
              <button className={style.signInWeb} onClick={logout}>
                Sign-Out
              </button>
            ) : (
              <button className={style.signInWeb} onClick={login}>
                Sign in
              </button>
            )}
          </div>
          <>
            <MediaQuery minWidth={768}>
              <Drawer
                open={openFilter}
                onClose={() => setOpenFilter(false)}
                anchor="left"
              >
                <Box
                  sx={{ width:"400px", }}
                  role="presentation"
                  // onClick={() => setOpenFilter(false)}
                  // onKeyDown={() => setOpenFilter(false)}
                >
                  <div style={{ height: "80px", background: "#af002b" }}></div>
                  <FilterComponent setOpenFilter={setOpenFilter} />
                </Box>
              </Drawer>
            </MediaQuery>
            <>
              <Drawer
                anchor="left"
                open={openMenu}
                onClose={() => setOpenMenu(false)}
              >
                <Box className="Boxbutton1" role="presentation">
                  <Button onClick={() => setOpenMenu(false)}>
                    <CloseIcon sx={{ color: "#AF002B" }} />
                  </Button>
                  <SidebarData />
                  <MediaQuery maxWidth={600}>
                    <button
                      className={style.logoutButton}
                      onClick={() => {
                        logout();
                      }}
                    >
                      Log out
                    </button>
                  </MediaQuery>
                </Box>
              </Drawer>

              <Drawer
                anchor="right"
                open={openProfile}
                onClose={() => setOpenProfile(false)}
              >
                <Box
                className={style.Boxbutton1}
                  sx={{
                    // width: "100%",
                    background: "#eeeeee",
                  }}
                  role="presentation"
                >
                  <Button
                    className={style.closebutton}
                    onClick={() => setOpenProfile(false)}
                  >
                    <CloseIcon sx={{ color: "#AF002B" }} />
                  </Button>
                  <EditProfile />
                </Box>
              </Drawer>
            </>
          </>
        </div>
      </nav>
    </>
  );
};

export default TabletScreenNavBar;

export const SidebarData = () => {
  const navigate = useNavigate();
  return (
    <div className={style.settingHeader}>
      <div
        className={style.settingIcons}
        onClick={() => {
          navigate("/notification");
        }}
      >
        <NotificationsIcon sx={{ color: "#AF002B" }} />
        <span className={style.settingText}>Notification</span>
      </div>
      <div
        className={style.settingIcons}
        onClick={() => {
          navigate("/support ", { state: { tag: "support" } });
        }}
      >
        <IconComponent icon={"support"} sx={{ color: "#AF002B" }} />
        <span className={style.settingText}>Support</span>
      </div>
      <div
        className={style.settingIcons}
        onClick={() => {
          handleOpenLink("https://cdn.onestup.com/Privacy%20policy.html");
        }}
      >
        <HttpsIcon sx={{ color: "#AF002B" }} />
        <span className={style.settingText}>privacy policy</span>
      </div>

      <div
        className={style.settingIcons}
        onClick={() => {
          handleOpenLink(
            "https://cdn.onestup.com/Customer%20Terms%20&%20Conditions.html"
          );
        }}
      >
        <InfoRoundedIcon sx={{ color: "#AF002B" }} />

        <span className={style.settingText}>Terms and Conditions</span>
      </div>
      <div
        className={style.settingIcons}
        onClick={() => {
          navigate("/support ", { state: { tag: "feedback" } });
        }}
      >
        <RateReviewIcon sx={{ color: "#AF002B" }} />
        <span className={style.settingText}>Feedback</span>
      </div>
    </div>
  );
};
