import React, { useContext, useEffect, useState } from "react";

import styles from "./login.module.css";
import { Link, useNavigate } from "react-router-dom";

import { useTracking } from "react-tracking";
import { IconComponent } from "../../../Icons/IconComponent";

import LoadingScreen from "../Loading/LoadingScreen";
import { useMediaQuery } from "react-responsive";
import { Context } from "../Context/Context";
import { TextField } from "@mui/material";
import colors from "../../../assets/colors";
import { toast } from "react-toastify";
import UseOnKeyPress from "../../../utils/UseOnKeyPress";

const Login = () => {
  const navigate = useNavigate();

  const islaptop = useMediaQuery({ maxWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });

  const iconSize = isMobile
    ? "8rem"
    : isTablet
    ? "20rem"
    : islaptop
    ? "70rem"
    : "50rem";
  const fontSize = isMobile ? "5rem" : isTablet ? "4rem" : "4rem";
  const iconStyle = {
    fontSize: `${fontSize}`,
    width: `${iconSize}`,
  };
  const { baseUrl } = useContext(Context);
  const { trackEvent } = useTracking();
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [mobileId, setMobileId] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [userAgent, setUserAgent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hasTrackingPermission = true;
        if (!hasTrackingPermission) {
        }
        const advertisingInfo = { id: "some_id", isAdTrackingLimited: false };
        !advertisingInfo.isAdTrackingLimited
          ? setMobileId(advertisingInfo.id)
          : setMobileId(null);
        setUserAgent(window.navigator.userAgent);
        setIpAddress("127.0.0.1");
        setModel("Web Model");
        trackEvent({ action: "ButtonClick", category: "UI Interaction" });
      } catch (error) {}
    };

    fetchData();
  }, [trackEvent]);

  const checkMobileNumber = () => {
    if (mobileNumber.length === 10) {
      getOtp();
    } else setError("Please enter a valid 10 digit mobile number");
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ph_no: mobileNumber,
        "user-type": "customer",
        device: "model",
        "client-ip": "127.0.0.1",
        "user-agent": userAgent,
        mobileId: "mobileId",
      };

      let Response = await fetch(`${baseUrl}/api/session/otp/generate`, {
        headers,
      });

      if (Response.ok) {
        navigate("/OtpVerification", {
          state: { headers, phoneNumber: mobileNumber },
        });

        toast.success("Mobile OTP successfully Send!");
      }
      if (!Response.ok) {
        const rejectedResponse = await Response.json();
        alert(rejectedResponse.msg);
      }
      setLoading(false);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const handelSubmit = () => {
    checkMobileNumber();
  };

  UseOnKeyPress(handelSubmit, "Enter");

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.containerpage}>
          <div className={styles.logincoverpage}>
            <IconComponent
              icon={"appName"}
              sx={{ ...iconStyle, color: "white" }}
            />

            <input
              type="text"
              placeholder="Enter your 10 digit Mobile Number"
              value={mobileNumber}
              inputMode="tel"
              maxLength={10}
              onFocus={() => {
                setError("");
              }}
              onChange={(e) => setMobileNumber(e.target.value)}
              key="numeric"
              required
            />
            <div className={styles.errortext}>{error}</div>

            <div className={styles.otpGenerated} onClick={handelSubmit}>
              <Link to="" style={{ textDecoration: "none" }}>
                <button type="submit">Generate OTP</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
