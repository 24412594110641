import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../pages/Context/Context";
import TabletScreenNavBar from "../../../Navbar/Screens/Tablet/TabletScreenNavBar";
import BusinessCard from "../../../pages/Cards/BussinessCards/BusinessCard";
import { LoadingResults } from "../../../pages/Loading/LoadingScreen";
import MediaQuery from "react-responsive";
import WebFooter from "../../WebFooter/WebFooter";
import Footer from "../../Footer";
import styles from "../Favorite/Favorites.module.css";

let offset = 0;
let finished = false;
let favCall = false;
const Favorites = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [allFavourites, setAllFavourites] = useState([]);
  const [loading, setLoading] = useState(false);
  const { baseUrl, sessionId } = useContext(Context);

  const getFavourites = async () => {
    const controller = new AbortController();
    favCall = controller;

    try {
      setLoading(true);
      const response = await fetch(
        `${baseUrl}/api/favorite?limit=10&offset=${offset}`,
        {
          method: "GET",
          headers: { sessionId },
          signal: controller.signal,
        }
      );
      const data = await response.json();

      if (data && data.length > 0) {
        if (offset !== 0) {
          setAllFavourites(
            [...allFavourites, ...data].filter((obj, index, arr) => {
              if (!obj?.phoneNumber) return false;
              return (
                index ===
                arr.findIndex((obj2) => obj.phoneNumber === obj2.phoneNumber)
              );
            })
          );
        } else {
          setAllFavourites(data);
          offset = offset + data.length;
        }
      } else {
        finished = true;
      }
    } catch (e) {
      controller.abort();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    offset = 0;
    finished = false;
    setAllFavourites([]);
    getFavourites();
  }, []);

  // const handleEndReached = () => {
  //   if (!finished && !favCall) {
  //     getFavourites();
  //   }
  // };

  return (
    <>
      <div>
        <TabletScreenNavBar />
      </div>
      {allFavourites.length === 0 && !loading ? (
        <div style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
         
        }}>
          <div className={styles.noResults}>
            <div className={styles.noesultssymbols}>😞🔍</div>
            <div>No results found.</div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.favoritesheader}>
            {allFavourites.map((item, index) => (
              <div key={index}>
                <BusinessCard item={item} />
              </div>
            ))}

            {/* <div ref={handleEndReached}></div> */}
          </div>
        </div>
      )}
      
     
      <MediaQuery maxWidth={768}>
        <div>
          <Footer />
        </div>
      </MediaQuery>
    </>
  );
};

export default Favorites;
