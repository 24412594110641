import React from "react";
import styles from "../WebFooter/webFooter.module.css"
import { IconComponent } from "../../../Icons/IconComponent";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import YouTubeIcon from '@mui/icons-material/YouTube';
import CustomButton from "../../ProfilePage/CustomButton/CustomButton";
import BusinessSearch from "../../../SearchInput/BusinessSearch/BusinessSearch";
import { BusinessPlayStore, CustomerPlayStore } from "../../pages/Customer/CustomerPlayStore";

const WebFooter = () => {
  return (
    <>
        <footer className={styles.footer} >
        <div >
          <IconComponent
              icon={"appName"}
              sx={{
                // marginTop: ["15px", "2px"],
                fontSize: "150px",
                
                color: "#af002b",
               height:"48px"
              }}
            />
        
            </div>

            <div className={styles.containericons}>
              <ul className={styles.socialicons}>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61555939253611">
                    <FaFacebook />
                  </a>
                </li>
                
                <li>
                  <a href="https://www.instagram.com/onestup_1?utm_source=qr&igsh=MXRiOGd3d3Zweng4Ng==">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/one-stup?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ">
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@Onestup100">
                    <YouTubeIcon sx={{fontSize:"2rem"}} />
                  </a>
                </li>
              </ul>
              <hr></hr>
            </div>
            <div style={{
              display:"flex",
              gap:'1rem'
             
            }}>
              <CustomerPlayStore color={"#af002b"}/>
              <BusinessPlayStore color={"#af002b"}/>
            </div>
        </footer>


    </>
  );
};
export default WebFooter;
