import React, { useContext, useState } from "react";
import styles from "./DetailUpdate.module.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Components/pages/Context/Context";

const DetailUpdate = ({ title, text }) => {
  const {
    name,
    email,
    emailVerified,
    phoneNumber,
    sessionId,
    baseUrl,
    updateContext,
  } = useContext(Context);

  const [newText, setNewText] = useState(text);
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});

  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const validate = () => {
    let isValid = true;

    if (title === "Full Name" && !newText) {
      handleError("Please input name", "Full Name");
      isValid = false;
    }
    if (title === "Email" && newText && !newText.match(/\S+@\S+\.\S+/)) {
      handleError(
        "Please input a valid email such as 'example@something.com'",
        "Email"
      );
      isValid = false;
    }
    if (isValid) {
      updateUser();
    }
  };

  const updateUser = async () => {
    try {
      const newDetails = {
        name,
        email,
        phoneNumber,
        emailVerified,
      };
      newDetails[title === "Full Name" ? "name" : "email"] = newText;
      const response = await fetch(`${baseUrl}/api/customer/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(newDetails),
      });
      const json = await response.json();
      if (response.ok) {
        setEdit(false);
        updateContext({ ...newDetails });
      } else {
        alert(
          "Something went wrong. User Details could not be updated. Please try again."
        );
      }
    } catch (err) {}
  };

  return (
    <div >
      <p className={styles.textcss}>{title}</p>
      {!edit ? (
        <div className={styles.Text}>
          <p className={styles.inputText}>{text}</p>
          {title !== "Phone Number" && (
            <button sx={{ color: "black" }} onClick={() => setEdit(true)}>
              Edit
            </button>
          )}
        </div>
      ) : (
        <>
          <input
            className={styles.Tex}
            type="text"
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
            placeholder={title}
            onFocus={() => handleError(null, title)}
          />
          {errors[title] && <p className={styles.error}>{errors[title]}</p>}
          <button className={styles.updatebutton} onClick={validate}>
            Update
          </button>
          <button
            className={styles.updatebutton}
            onClick={() => {
              setEdit(false);
              setNewText(text);
              setErrors({});
            }}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  );
};

export default DetailUpdate;
