import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconComponent } from "../../../../Icons/IconComponent";
import { useNavigate } from "react-router-dom";
import "../Notification/Notification.css"
const Notification = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  
  return (
    <div className="container">
      <ChevronLeftIcon
        onClick={goBack}
        sx={{ color: "#AF002B", fontSize: [40, 60] }}
      />

      <div className="SerachHeader" search={query} setSearch={setQuery}>
        <div className="shcontent">
          <IconComponent
            icon="logo"
            left={-1}
            iconFamily="IcoMoon"
            iconStyle={{ left: -1 }}
            sx={{ color: "#FFF", marginBottom: [0, 10] }}
            size={24}
            color="brown"
          />
        </div>
        <input
          type="text"
          placeholder="Notifications"
          className="contentText"
        />
        <IconComponent
          icon="search"
          name="search"
          iconFamily="IcoMoon"
          size={20}
          sx={{ color: "#AF002B", marginLeft: 1 }}
        />
      </div>
    </div>
  );
};

export default Notification;
