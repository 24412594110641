import React from "react";
import { IconComponent } from "../../../Icons/IconComponent";
import { searchIcons } from "../Context/Context";
import MediaQuery from "react-responsive";
import Iconsline from "../../../SearchInput/Iconsline";
import { HomeIcons } from "../../../SearchInput/BusinessSearch/BusinessSearch";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import style from "./HomePage.module.css";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { BusinessPlayStore, CustomerPlayStore, DisplayCustomerApp } from "../Customer/CustomerPlayStore";
import backgroundImage from "../../../assets/Images/1.png"
const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.appcontainer}>
        <div className={style.containerold}>
          <MediaQuery maxWidth={600}>
            <nav
              className={style.navElements}
              onClick={() => {
                navigate("/Login");
              }}
            >
              <FaIcons.FaBars className={style.menuIcon} />
              <IconComponent
                icon={"appName"}
                sx={{
                  fontSize: "150px",
                  color: "#ffffff",
                }}
              />
              <IconComponent icon={"userEmpty"} sx={{ fontSize: "24px" }} />
            </nav>
          </MediaQuery>
          <MediaQuery minWidth={600}>
            <div
              className={style.header}
              onClick={() => {
                navigate("/Login");
              }}
            >
              <IconComponent
                icon={"userEmpty"}
                sx={{ fontSize: "24px" }}
                onClick={() => {
                  navigate("/Login");
                }}
              />
              <p
                onClick={() => {
                  navigate("/Login");
                }}
                style={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: 600,
                  margin: "0px",
                }}
              >
                Sign In
              </p>
            </div>
          </MediaQuery>
          

          <div className={style.maincontent}
           onClick={() => {
            navigate("/Login");
          }}>
            <MediaQuery minWidth={600}>
              <IconComponent
                icon={"appName"}
                sx={{
                  fontSize: "350px",
                  color: "#FFF",
                  height: "100px",
                }}
              />
              <p
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  textDecoration: "underline",
                  fontWeight: 600,
                }}
              >
                Find all your needs is one platform
              </p>
            </MediaQuery>

            <div className={style.searchBar}  onClick={() => {
                navigate("/Login");
              }}>
              <p>pharmacy, plumber, etc...</p>
              <SearchIcon
                icon="search"
                size={20}
                sx={{ fontSize: [20, 20], color: "#AF002B", marginLeft: 1 }}
              />
            </div>
            <MediaQuery minWidth={600}>
              <Iconsline iconList={searchIcons} color={"white"}  onClick={() => {
                navigate("/Login");
              }}/>
            </MediaQuery>
            <MediaQuery maxWidth={599}>
              <div style={{
                  backgroundImage: `url(${backgroundImage})`,
              }}>
              <HomeIcons iconList={searchIcons}  onClick={() => {
                navigate("/Login");
              }}/>
              </div>
            </MediaQuery>
          </div>
         
          <MediaQuery minWidth={600}>
            <div className={style.footer}>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <a href="https://www.facebook.com/profile.php?id=61555939253611">
                  <FaFacebook
                    style={{
                      fontSize: "1.5rem",
                    }}
                  />
                </a>
                <a href="https://www.instagram.com/onestup_1?utm_source=qr&igsh=MXRiOGd3d3Zweng4Ng==">
                  <FaInstagram
                    style={{
                      fontSize: "1.5rem",
                    }}
                  />
                </a>
                <a href="https://www.linkedin.com/in/one-stup?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ">
                  <FaLinkedin
                    style={{
                      fontSize: "1.5rem",
                    }}
                  />
                </a>
                <a href="https://www.youtube.com/@Onestup100">
                  <YouTubeIcon
                    style={{
                      fontSize: "1.5rem",
                    }}
                  />
                </a>
              </div>
              <div className={style.playstores}>
                <CustomerPlayStore/>
                <BusinessPlayStore/>
              </div>
            </div>
          </MediaQuery>
        </div>
      </div>
    </>
  );
};

export default HomePage;
