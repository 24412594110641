import React, { useContext, useEffect, useState } from "react";
import { IconComponent } from "../../../Icons/IconComponent";
import { Fonts } from "../../../assets/fonts";
import colors from "../../../assets/colors";
import style from "./CustomButton.module.css";

import { actionCall } from "../../pages/Cards/BussinessCards/BusinessCard";
import { Context } from "../../pages/Context/Context";
import { handleOpenLink } from "../../pages/Details/Details";
import {
  _actionObject,
  _item,
  _views,
} from "../BusinessProfile/BusinessProfile";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import MediaQuery from "react-responsive";

const CustomButton = ({ actionObject, user }) => {
  const { baseUrl, sessionId } = useContext(Context);
  const [favourite, setFavourite] = useState(false);
  const [usePhoneNumber, setUSerPhoneNumber] = useState();
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const checkFavourite = async () => {
    try {
      const favObj = {
        bookmarkType: _actionObject?.receiverType,
        bookmarkNumber: _actionObject?.receiverPhone,
      };
      const Response = await fetch(`${baseUrl}/api/favorite/check`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          sessionId,
        },
        body: JSON.stringify(favObj),
      });
      const ResponseJson = await Response.json();
      setFavourite(ResponseJson.condition);
    } catch (err) {}
  };
  useEffect(() => {
    checkFavourite();
  }, [checkFavourite]);
  const updateFavourite = async () => {
    try {
      const favBody = {
        bookmarkType: _actionObject?.receiverType,
        bookmarkNumber: _actionObject?.receiverPhone,
        isBookmark: !favourite,
      };
      const newFav = _item;
      const response = await fetch(`${baseUrl}/api/favorite`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          sessionId,
        },
        body: JSON.stringify(favBody),
      });
      if (response.status === 200) {
        if (!favourite) {
          toast.success("Favourite updated successfully");
        } else {
          toast.error("Favourite removed!!!");
        }
        setFavourite(!favourite);
      }
    } catch (err) {}
  };

  const getProfileUrl = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/search/share`, {
        headers: {
          sessionId,
          profileType: actionObject?.receiverType,
          phoneNumber: actionObject?.receiverPhone,
        },
      });
      const responseJson = await response.json();
      if (responseJson.url) {
        return `https://www.onestup.com/${responseJson.url}/deep-link.html`;
      }
    } catch (err) {}
    return null;
  };

  const handleShare = async () => {
    const profileUrl = await getProfileUrl();
    if (profileUrl) {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Profile Link",
            url: profileUrl,
          });
        } catch (error) {}
      } else {
        try {
          await navigator.clipboard.writeText(profileUrl);
          toast.success("Profile link copied to clipboard");
        } catch (error) {
          toast.error("Failed to copy link to clipboard");
        }
      }
    } else {
      toast.error("Failed to get profile URL");
    }
  };

  const handlePhoneNumber = () => {
    actionObject.actionType = "call";
    actionCall(actionObject, baseUrl, sessionId);

    const isMobileView = window.innerWidth <= 600;

    if (isMobileView) {
      handleOpenLink(`tel:${actionObject.receiverPhone}`);
    } else {
      setUSerPhoneNumber(actionObject.receiverPhone);
      setShowPhoneNumber(true);
    }
  };

  return (
    
    
    <div
      style={{
        padding: "0.5rem",
      }}
    >
      {actionObject.receiverType === "business" && user.deliveryNote && (
        <div className={style.deliverybutton}>
          <IconComponent
            icon={"homeDelivery"}
            sx={{ fontSize: [30, 50], color: "brown" }}
          />
          <p
            style={{
              fontFamily: Fonts.dmSansRegular,
              color: colors.primary,
              paddingLeft: 12,
            }}
          >
            {user.deliveryNote}
          </p>
        </div>
      )}
{/* 
      {actionObject.receiverType === "business" && user && ( */}
        <div className={style.buttonAll}>
          <div className={style.innerbuttons}>
            <p
              style={{
                fontSize: "1.5rem",
              }}
            >
              {_views?.toLocaleString("en-IN")}
            </p>
            <p>views</p>
          </div>

          <div className={style.innerbuttons}>
            <button
              onClick={() => {
                handlePhoneNumber();
              }}
            >
              <IconComponent
                icon={"phone"}
                sx={{ fontSize: 31, color: "#ffffff" }}
              />
            </button>
            <MediaQuery minWidth={600}>
              <p className="label">
                {showPhoneNumber ? usePhoneNumber : "call"}
              </p>
            </MediaQuery>
            <MediaQuery maxWidth={599}>
              <p className={style.labe}>Call</p>
            </MediaQuery>
          </div>

          <div className={style.innerbuttons}>
            <Button
              onClick={() => {
                actionObject.actionType = "chat";
                actionCall(_actionObject, baseUrl, sessionId);
                handleOpenLink(
                  `https://wa.me/91${
                    user?.whatsappNumber
                      ? user.whatsappNumber
                      : _actionObject?.receiverPhone
                  }`
                );
              }}
            >
              <IconComponent
                icon={"messageNotif"}
                sx={{ fontSize: 31, color: "#ffffff", }}
              />
            </Button>
            <p>Message</p>
          </div>

          <div className={style.innerbuttons}>
            <Button onClick={updateFavourite}>
              <IconComponent
                icon={"filledHeart"}
                sx={{
                  fontSize: 31,
                  color: favourite ? "#e4f300" : colors.white,
                 
                }}
              />
            </Button>
            <p>Favourites </p>
          </div>

          <div className={style.innerbuttons}>
            <Button onClick={handleShare}>
              <IconComponent
                icon={"share"}
                sx={{ fontSize: 31, color: "#ffffff", }}
              />
            </Button>
            <p>Share</p>
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default CustomButton;
