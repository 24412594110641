import { useEffect } from "react";
import { useParams } from "react-router-dom";

const DeepLink = () => {
  const { id } = useParams();

  useEffect(() => {
    window.location.replace(`onestup://api/search/profile/${id}`);
  }, [id]);

  return <h3> Please upgrade the app to the latest version. </h3>;
};
export default DeepLink;
