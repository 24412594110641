import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Components/pages/Loginpages/Login";
import OtpGenerated from "./Components/pages/OtpPage/OtpGenerated";
import LoginError from "./Components/pages/LoginError/LoginError";
import Notification from "./Components/Navbar/MenuBar/Notification/Notification";
import BusinessComponents from "./Components/pages/BusinessComponent/BusinessComponents";
import BusinessProfile from "./Components/ProfilePage/BusinessProfile/BusinessProfile";
import BusinessSearch from "./SearchInput/BusinessSearch/BusinessSearch";
import { Context, ContextProvider } from "./Components/pages/Context/Context";
import BusinessCard from "./Components/pages/Cards/BussinessCards/BusinessCard";
import PreViewImage from "./Components/ProfilePage/BusinessProfile/PhotosBusiness/PreViewImage";
import Details from "./Components/pages/Details/Details";
import Home from "./Components/Footer/Tabs/Home/Home";
import Support from "./Components/Navbar/MenuBar/Support/Support";
import EditProfile from "./Components/ProfilePage/EditProfile/EditProfile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WriteReviewPage from "./Components/ProfilePage/Reviews/WirteReview";
import ImagesComponent from "./Components/ProfilePage/BusinessProfile/PhotosBusiness/ImagesComponent";
import HomePage from "./Components/pages/Homepages/HomePage";
import Favorites from "./Components/Footer/Tabs/Favorite/Favorites";
import DeepLink from "./utils/DeepLink";

const App = () => {
  const { isLoggedIn, setIsLoggedIn, updateContext } = useContext(Context);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      updateContext({
        sessionId: token,
      });
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/Home" element={<Home />} />
            <Route path="/support" element={<Support />} />
            <Route path="/Customerprofile" element={<EditProfile />} />
            <Route
              path="/businessComponents"
              element={<BusinessComponents />}
            />
            <Route path="/businessSearch" element={<BusinessSearch />} />
            <Route path="/businessCard" element={<BusinessCard />} />
            <Route path="/Preview" element={<PreViewImage />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/notification" element={<Notification />} />
            <Route
              path="/businessprofile/:itemType"
              element={<BusinessProfile />}
            />
            <Route path="/:id/deep-link.html" element={<DeepLink />} />
            <Route path="/writeReview" element={<WriteReviewPage />} />
            <Route path="/images" element={<ImagesComponent />} />
            <Route path="*" element={<Navigate to="/Home" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/:id/deep-link.html" element={<DeepLink />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/OtpVerification" element={<OtpGenerated />} />
            <Route path="/LoginError" element={<LoginError />} />
            <Route path="/Details" element={<Details />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <ContextProvider>
    <Router>
      <App />
      <ToastContainer autoClose={2000} />
    </Router>
  </ContextProvider>
);

export default AppWrapper;
