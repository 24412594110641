import React, { useState } from "react";
import styles from "./CustomerPaystore.module.css";
import { IconComponent } from "../../../Icons/IconComponent";
import { Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CustomerPlayStore = ({ color }) => {
  return (
    <div>
      <div className={styles.playstores}>
        <p style={{ color }}>Customer-app</p>

        <a href="https://play.google.com/store/apps/details?id=com.onestup.oneStup&amp;pcampaignid=web_share">
          <img
            src="https://cdn.onestup.com/static/play_Store.svg"
            alt="Apple App Store"
          />
        </a>
      </div>
    </div>
  );
};

export const BusinessPlayStore = ({ color }) => {
  return (
    <div>
      <div className={styles.playstores}>
        <p style={{ color }}>Business-app</p>
        <a href="https://play.google.com/store/apps/details?id=com.onestup.onestupBusiness">
          <img
            src="https://cdn.onestup.com/static/play_Store.svg"
            alt="Google Play Store"
          />
        </a>
      </div>
    </div>
  );
};

export const DisplayCustomerApp = () => {
  const [isCardVisible, setIsCardVisible] = useState(true)

  const handleClose =()=>{
    setIsCardVisible(false)
  }
  return (
    <>
    {isCardVisible && (
      <div>
        <Card className={styles.CustomerAppCard}>
          <div
          onClick={handleClose}
            style={{
              position: "absolute",
              top: "-0px",
              right: "0px",
            }}
          >
             <CloseIcon sx={{ color: "#AF002B" }}  />
          </div>
          <div>
            <div
              style={{
                background: "#AF002B",
              }}
            >
              <IconComponent
                icon={"appName"}
                sx={{
                  fontSize: "70px",
                  color: "#fff",
                }}
              />
            </div>

            <p
              style={{
                fontSize: "0.6rem",
              }}
            >
              Rating 5.8
            </p>
          </div>

          <div style={{ display: "flex" }}>
            <div>
              <h1>Install Customer App</h1>
              <p
                style={{
                  fontSize: "0.6rem",
                }}
              >
                All your daily needs in one place
              </p>
            </div>

            <div className={styles.install}>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.onestup.oneStup&pcampaignid=web_share")
                }
              >
                Install
              </button>
            </div>
          </div>
        </Card>
      </div>
      )}
    </>
  );
};
