import { Box, Drawer, Tab, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { TabContext, TabList } from "@mui/lab";
import { FilterComponent } from "../../SearchInput/BusinessSearch/BusinessSearch";
import { handleHomeClick } from "../Navbar/Screens/Tablet/TabletScreenNavBar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#AF002B",
    },
    secondary: {
      main: "#AF002B",
    },
  },
});

const Footer = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [openFilter, setOpenFilter] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case "1":
        navigate("/Home");
        break;
      case "2":
        navigate("/favorites");
        break;
      case "3":
        setOpenFilter(true);
        break;
      default:
        break;
    }
  };

  const handleCloseDrawer = () => {
    setOpenFilter(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              background: "#cfc9c9",
              color: "#EEEEEE",
              borderTop: "1px solid #ccc",
            }}
          >
            <TabList
              sx={{
                justifyContent: "space-around",
                "& .MuiTab-root": {
                  minWidth: "35%",
                },
              }}
              aria-label="Tabs-example"
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                label="Home"
                value="1"
                icon={<HomeIcon />}
                sx={{
                  fontFamily: "DM Sans ,sans-serif",
                  fontSize: 10,
                  fontWeight: 600,
                }}
                onClick={handleHomeClick}
              />
              <Tab
                label="Favourites"
                icon={<FavoriteBorderIcon />}
                value="2"
                sx={{
                  fontFamily: "DM Sans ,sans-serif",
                  fontSize: 10,
                  fontWeight: 600,
                }}
              />
              <Tab
                label="Filters"
                icon={<FilterAltIcon sx={{ fontSize: 30 }} />}
                value="3"
                sx={{
                  fontFamily: "DM Sans ,sans-serif",
                  fontSize: 10,
                  fontWeight: 600,
                }}
              />
            </TabList>
          </Box>
        </TabContext>
        <Drawer open={openFilter} onClose={handleCloseDrawer} anchor="bottom">
          <Box sx={{ width: "100%" }} role="presentation">
            <FilterComponent setOpenFilter={setOpenFilter} />
          </Box>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
