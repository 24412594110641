import React, { useEffect } from 'react'

const UseOnKeyPress = (callback,targetKey) => {
    useEffect(()=>{
        const handleKeyPress = (event) => {
            if(event.key === targetKey){
          callback();
            }
        }
  window.addEventListener("keydown",handleKeyPress);
  return ()=>{
    window.removeEventListener("keydown",handleKeyPress);
  }
    },[callback,targetKey])

    
  
}

export default UseOnKeyPress
