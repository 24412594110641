const colors = {
  primary: "#AF002B",
  secondary: "#EEF1F4",
  white: "#FFFFFF",
  annotation: "#F6CA56",
  logoColor: "#F2F2F2",
  black: "#000000",
  warning: "#DA4A4A",
  inputBoxBorder: "#5A5A5A",
  placeholderColor: "#C0C0C0",
  onboardBackground: "#E5E5E5",
  alertBackground: "#FCFCFC",
  alertOptionsText: "#0373F0",
  activeDotsColor: "#1D272F",
  inactiveDotsColor: "#EBECF3",
  lightGrey: "#EBEBEB",
  mediumGrey: "#BEBEBE",
  toggleSwitch: "#25D366",
  separator: "#CDCDCE",
  star: "#F9770099",
};
export default colors;
