import React from "react";
import style from "./Home.module.css";
import WebFooter from "../../WebFooter/WebFooter";

import BusinessSearch from "../../../../SearchInput/BusinessSearch/BusinessSearch";
import MediaQuery from "react-responsive";
import TabletScreenNavBar from "../../../Navbar/Screens/Tablet/TabletScreenNavBar";
import Footer from "../../Footer";
export default function Home() {
  return (
    <>
      <div className={style.containerhomepage}>
        <div className={style.navbarwrapper}>
          <TabletScreenNavBar />
        </div>

        <MediaQuery minWidth={600}></MediaQuery>

        <BusinessSearch />

        <MediaQuery minWidth={768}>
          <div className={style.footerwrapper}>
            <WebFooter />
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <div className={style.businessFooter}>
            <Footer />
          </div>
        </MediaQuery>
      </div>
    </>
  );
}
