import React from 'react';
import { useLocation } from 'react-router-dom';

const ImagesComponent = () => {
  const location = useLocation();
  const arr = location.state ? location.state.arr : [];
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <h1 >All Photos</h1>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap:"1rem",
        justifyContent: 'center',
        padding:"1rem"
      }}>
        {arr && arr.length > 0 ? (
          arr.map((item, index) => (
            <div key={index}>
              <img
                src={item.src}  
                alt={`Image ${index + 1}`}
                style={{ maxWidth: '380px', height: '350px',borderRadius:"12px" }}
              />
            </div>
          ))
        ) : (
          <p style={{

            fontSize:'1rem'
          }}>No images to display</p>
        )}
      </div>
    </div>
  );
};

export default ImagesComponent;
