import React, { useContext, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { Fonts, Fontsize } from "../../../../assets/fonts";
import { Context } from "../../../pages/Context/Context";
import styles from "./Support.module.css";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { IconComponent } from "../../../../Icons/IconComponent";
import colors from "../../../../assets/colors";
import {
  getLocation,
  reverseGeoCode,
} from "../../../../utils/LocationComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FcOk } from "react-icons/fc";

let address = {};
const Support = () => {
  const { name, baseUrl, phoneNumber, sessionId } = useContext(Context);
  const location = useLocation();
  const { state } = location;
  const tag = state && state.tag;

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = async () => {
    try {
      const { latitude, longitude } = await getLocation();
      address = await reverseGeoCode(latitude, longitude);
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      setTitle("");
      setMessage("");
      goBack();
    }, 1113000);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const sendEmail = async () => {
    try {
      const emailBody = {
        name: name,
        ph_no: phoneNumber,
        userType: "customer",
        subject: tag === "support" ? title.trim() : "Feedback",
        description: message.trim(),
        city: address?.city,
        state: address?.region,
      };

      const response = await fetch(`${baseUrl}/api/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sessionId: sessionId,
        },
        body: JSON.stringify(emailBody),
      });

      if (response.ok) {
        setStatus(true);
      } else {
        setStatus(false);
      }

      handleOpen();
    } catch (error) {}
  };

  return (
    <div className={styles.page}>
      <div className={styles.SupportContainer}>
        <div className={styles.backbutton}>
          <ChevronLeftIcon
            onClick={goBack}
            sx={{ color: "#AF002B", fontSize: 30 }}
          />

          <p className={styles.heading}>
            {tag === "support" ? "Contact Support" : "Feedback"}
          </p>

          {/* {tag == "support" ? "Contact Support" : "Feedback"} */}
        </div>
        {tag === "support" && (
          <>
            <div className={styles.supportProfileborder}>
              <div className={styles.supportProfile}>
                <IconComponent
                  icon={"support"}
                  sx={{
                    fontSize: 90,
                    color: "#FFF",
                  }}
                />
              </div>
            </div>

            <div style={{}}>
              <p className={styles.headingText}> Name</p>
              <div className={styles.inputfeild}>
                <p>{name}</p>
              </div>
              <p className={styles.headingText}>Phone Number</p>
              <div className={styles.inputfeild}>
                <p>{phoneNumber}</p>
              </div>
              <p className={styles.headingText}> Subject</p>

              <input
                className={styles.inputfeild}
                type="text"
                placeholder="Subject"
                style={{ outline: "none" }}
              />
              <p className={styles.headingText}>
                {tag === "support" ? "Message" : "Tell us how we can improve"}
              </p>
            </div>
          </>
        )}
        <div className={styles.textArea}>
          {/* <p className="headingText"> Message</p> */}

          <textarea
            style={{
              flex: tag === "support" ? 1 : 0.5,
              textAlignVertical: "top",
              borderColor: error ? colors.warning : colors.black,
            }}
            value={message}
            className={styles.subjectText}
            onChange={(text) => {
              setMessage(text.target.value);
            }}
            onFocus={() => {
              setError("");
            }}
            placeholder={
              tag === "support"
                ? "Message"
                : "Help us with your feedback to get better"
            }
            multiline={true}
          />
          {error && (
            <p
              style={{
                color: colors.warning,
                fontSize: Fontsize.caption1,
                fontFamily: Fonts.dmSansRegular,
                marginLeft: 12,
              }}
            >
              {error}
            </p>
          )}
          {tag === "support" && (
            <p
              style={{
                fontFamily: Fonts.dmSansRegular,
                fontSize: Fontsize.menuItem,
                color: colors.black,
              }}
            >
              We respond to most messages within 24 hours
            </p>
          )}
        </div>

        <div className={styles.supportButton}>
          <button
            style={{
              border: "none",
              color: colors.white,
              backgroundColor: colors.primary,
            }}
            onClick={() => {
              message.length > 0
                ? sendEmail()
                : setError("Message cannot be blank");
            }}
          >
            Submit{" "}
          </button>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // width: 400,
                // bgcolor: "background.paper",
                // border: "2px solid #000",
                // boxShadow: 24,
                // p: 4,
              }}
            >
              {status ? (
                <div
                  style={{
                    backgroundColor: "white",
                    width: 280,
                    height: 180,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 40,
                    boxShadow:
                      "0px 0px 10px 0px rgba(0,0,0,0.5), " /* Top white shadow */ +
                      "0px 0px 10px 0px rgba(0,0,0,0.5), " /* Right white shadow */ +
                      "0px 0px 10px 0px rgba(255,255,255,0.5), " /* Bottom white shadow */ +
                      "0px 0px 10px 0px rgba(255,255,255,0.5)" /* Left white shadow */,
                  }}
                >
                  <FcOk
                    fontSize={80}
                    style={{
                      color: "FcOK" ? "#32CD32" : "#D90000",
                      backgroundColor: "#FFDADA",
                      borderRadius: "50%",
                      margin: "20 39 20 40",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: Fonts.interSemibold,
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                    {tag === "support"
                      ? "Your request has been submitted successfully"
                      : "Thank you for your feedback"}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    width: 280,
                    height: 180,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 40,
                    boxShadow:
                      "0px 0px 10px 0px rgba(0,0,0,0.5), " /* Top white shadow */ +
                      "0px 0px 10px 0px rgba(0,0,0,0.5), " /* Right white shadow */ +
                      "0px 0px 10px 0px rgba(255,255,255,0.5), " /* Bottom white shadow */ +
                      "0px 0px 10px 0px rgba(255,255,255,0.5)" /* Left white shadow */,
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                    style={{
                      color: "FcOK" ? "#32CD32" : "#D90000",
                      backgroundColor: "#FFDADA",
                      borderRadius: "50%",
                      margin: "20 39 20 40",
                    }}
                  />

                  <p
                    style={{
                      fontFamily: Fonts.interSemibold,
                      fontSize: Fontsize.title3,
                    }}
                  >
                    Something went wrong. Please try again
                  </p>
                </div>
              )}
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Support;

export const PhotosPopups = ({ text, icon }) => {
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: colors.white,
          width: 313,
          height: 250,
          borderRadius: 5,
          justifyContent: "center",
          alignItems: "center",
          shadowColor: colors.black,
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.5,
          shadowRadius: 4,
          elevation: 10,
        }}
      >
        <FontAwesomeIcon
          size={70}
          icon={icon}
          color={icon === "fa-solid fa-circle-check" ? "#32CD32" : "#D90000"} // Adjust color condition based on the icon name
          style={{
            backgroundColor: "#FFDADA",
            margin: 20,
          }}
        />
        <p
          style={{
            fontFamily: Fonts.interSemibold,
            fontSize: Fontsize.title3,
            margin: 10,
            textAlign: "center",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
