import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FaStar } from "react-icons/fa";
import { Button, Rating, Popover, Avatar, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Context, deleteLocalImages } from "../../pages/Context/Context";
import moment from "moment";
import styles from "./WirteReview.module.css";
import { IconComponent } from "../../../Icons/IconComponent";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import colors from "../../../assets/colors";
export const hscrollRef = React.createRef();

export const handleHorizontalScroll = () => {
  if (hscrollRef.current) {
    const { scrollTop, scrollLeft, scrollWidth, offsetWidth } =
      hscrollRef.current;
  }
};
let isAnonymous;
const WriteReviewPage = () => {
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [autoCloseTimeout, setAutoCloseTimeout] = useState(null);
  const { actionObject, avgRating, _myReview } = location.state || {};
  const receiver = { actionObject, avgRating, _myReview };
  const {
    baseUrl,
    sessionId,
    name,
    imageBaseUrl,
    updateContext,
    images,
    logoUrl,
  } = useContext(Context);
  const navigate = useNavigate();
  const tag = "review";
  const [text, setText] = useState(
    receiver._myReview.reviewText ? receiver._myReview.reviewText : ""
  );

  const [failedImages, setFailedImages] = useState([]);
  const [check, setCheck] = useState(false);

  const [uploadedImageNames, setUploadedImageNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [starRating, setStarRating] = useState(
    receiver._myReview.rating ? receiver._myReview.rating : 0
  );
  const [reviewImages, setReviewImages] = useState([]);
  const _arr = Object.keys(reviewImages);

  useEffect(() => {
    isAnonymous = false;

    if (receiver._myReview.images) {
      const imagesObj = receiver._myReview.images.reduce((acc, el) => {
        acc[el] = {
          url: `${imageBaseUrl}/reviews/${receiver.actionObject.receiverType}/${el}`,
        };
        return acc;
      }, {});
      setReviewImages(imagesObj);
    }
  }, []);

  useEffect(() => {
    if (check) {
      const _failed = failedImages.length;
      if (_failed > 0) {
        setLoading(false);
        const confirmation = toast.error(
          `${_failed} images were not uploaded. Would you like to reupload the images or continue to submit the review without the failed images?`
        );
        if (confirmation) {
          reuploadFailedImages();
        } else {
          continueWithoutFailedImages();
        }
      } else {
        setCheck(false);
        sendReview();
      }
    }
  }, [check]);

  const reuploadFailedImages = () => {
    setLoading(true);
    uploadReviewImages(failedImages);
  };

  const continueWithoutFailedImages = () => {
    setLoading(true);
    setCheck(false);
    sendReview();
  };

  const showModal = (status) => {
    setTimeout(() => {
      if (status) {
        toast.success("Review Succefully uploaded ");
        navigate(`/businessprofile/${receiver.actionObject.receiverType}`, {
          state: {
            actionObject: receiver.actionObject,
            avgRating: receiver.avgRating,
            newReview: {
              ...receiver._myReview,
              rating: starRating,
              reviewText: text,
              images: _arr.filter((r) => !failedImages.includes(r.name)),
              reviewerName: isAnonymous ? null : name,
              reviewerType: isAnonymous ? null : "customer",
              reviewerImage: isAnonymous ? null : images,
            },
          },
        });
      }
    }, 3000);
  };

  const sendReview = async () => {
    try {
      const reviewObject = {
        id: receiver._myReview.id ? receiver._myReview.id : 0,
        receiverPhone: receiver.actionObject?.receiverPhone,
        receiverType: receiver.actionObject?.receiverType,
        reviewText: text,
        rating: starRating,
        reviewerPhone: receiver.actionObject?.viewerPhone,
        reviewerType: receiver.actionObject?.viewerType,
        images: _arr.filter((r) => !failedImages.includes(r)),
        isFeedback: true,
        isAnonymous: isAnonymous,
      };

      let response = await fetch(`${baseUrl}/api/reviews`, {
        method: "POST",
        headers: { "Content-Type": "application/json", sessionId },
        body: JSON.stringify(reviewObject),
      });
      if (response.ok) {
        setLoading(false);
        setStatus(true);
        showModal(true);
        let responseJson = await response.json();
        receiver._myReview.id = responseJson.id;
      } else {
        setLoading(false);
        setStatus(false);
        showModal(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const uploadReviewImages = async (arr) => {
    try {
      for (let img of arr) {
        let image = img.name;
        const imageData = new FormData();
        imageData.append("image", img);
        const response = await fetch(
          `${baseUrl}/api/img/review/${receiver.actionObject?.receiverType}/${image}`,
          {
            method: "POST",
            headers: { sessionId },
            body: imageData,
          }
        );

        if (response.ok) {
          setUploadedImageNames((prev) => [...prev, image]);
        } else {
          setFailedImages((prev) => [...prev, image]);
        }
      }
      setCheck(true);
    } catch (err) {
      setCheck(true);
    }
  };

  const removeImage = (el, i) => {
    if (!reviewImages[el]) {
      return;
    }

    const imageUrl = reviewImages[el].url;

    setReviewImages((prevReviewImages) => {
      const updatedReviewImages = { ...prevReviewImages };
      delete updatedReviewImages[el];
      return updatedReviewImages;
    });

    deleteLocalImages(imageUrl);
  };

  const deleteReviewImage = async (name) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/img/review/${receiver._myReview.id}/${receiver.actionObject?.receiverType}/${name}`,
        { method: "DELETE", headers: { sessionId } }
      );
      if (response.ok) {
        removeImage(name);
      }
    } catch (err) {}
  };

  const storeImages = async (key, value) => {
    try {
      updateContext({ images: key, logoUrl: value });
      localStorage.setItem(`${key}`, value);
    } catch (e) {}
  };

  const handleaddPhotos = async (img, name) => {
    try {
      setUploading(true);
      const deleteLogo = async () => {
        try {
          let keys = await localStorage.getAllKeys();
          const logoKey = keys.filter((k) => k.includes("logo"));
          if (logoKey[0]) {
            const response = await fetch(
              `${baseUrl}/api/img/logo/${logoKey[0]}`,
              {
                method: "DELETE",
                headers: { sessionId },
              }
            );
            if (response.ok) {
              const logoUri = localStorage.getItem(logoKey[0]);
              deleteLocalImages(logoUri);
              localStorage.removeItem(logoKey[0]);
            }
          }
        } catch (error) {}
      };

      if (tag === "logo") await deleteLogo();

      const imageData = new FormData();
      imageData.append("image", img);
      const response = await fetch(
        `${baseUrl}/api/img/${tag}/${receiver.actionObject.receiverType}/${name}`,
        {
          method: "POST",
          headers: { sessionId },
          body: imageData,
        }
      );

      if (response.ok) {
        toast.success(" ReviewImages Succefully uploaded ");
        setUploadedImageNames((prev) => [...prev, name]);
        storeImages(name, img);
      } else {
        setFailedImages((prev) => [...prev, name]);
      }
    } catch (err) {
      setFailedImages((prev) => [...prev, name]);
    } finally {
      setUploading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={styles.wirtepage}>
      <div className={styles.Containerwirte}>
        <div className={styles.header}>
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <Avatar
            alt="User Avatar"
            src={logoUrl ? logoUrl : `${imageBaseUrl}/logo/customer/${images}`}
            sx={{
              width: 49,
              height: 49,
              margin: "1rem",
              borderRadius: "30px",
            }}
          />

          <p style={{ fontSize: "1rem", color: colors.black }}>
            Rate your experience
          </p>
        </div>
        <div className={styles.starratingcontainer}>
          <Rating
            name="half-rating"
            value={starRating}
            precision={0.5}
            onChange={(event, newValue) => setStarRating(newValue)}
            icon={<FaStar size={19} />} // Customize the icon size as needed
            emptyIcon={<FaStar size={19} style={{ opacity: 0.55 }} />} // For empty stars
            max={5}
          />
        </div>
        <div className={styles.textarea}>
          <textarea
            value={text}
            name="postContent"
            rows={8}
            style={{ padding: 10, background: "#e5e5e5", marginTop: 10 }}
            cols={40}
            onChange={(e) => setText(e.target.value)}
            className={styles.reviewInput}
            placeholder="Write your review here"
          />
        </div>
        {_arr.length > 0 ? (
          <div
            className={styles.uploadReviewImages}
            ref={hscrollRef}
            onScroll={handleHorizontalScroll}
          >
            {_arr.map((key, index) => (
              <div
                key={index}
                style={{
                  zIndex: "1px",
                  width: "145px",
                  height: "160px",

                  position: "relative",
                }}
              >
                <CloseIcon
                  style={{
                    position: "absolute",
                    top: "5px",
                    cursor: "pointer",
                    zIndex: 2,
                    color: "white",
                    backgroundColor: "#af002b",
                    borderRadius: "50%",
                  }}
                  onClick={() => deleteReviewImage(key)}
                />
                <img
                  src={reviewImages[key].url}
                  alt={key}
                  className={styles.imagePrevie}
                  style={{
                    width: "160px",
                    height: "160px",
                    borderRadius: 6,
                  }}
                />

                <IconComponent
                  icon={"imageUpload"}
                  sx={{ fontSize: 160, color: "#ffffff" }}
                  onClick={handleClick}
                />
                <IconComponent
                  icon={"ImageAdding"}
                  sx={{ fontSize: 150, color: "#ffffff" }}
                  onClick={handleClick}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
            }}
          >
            <IconComponent
              icon={"imageUpload"}
              sx={{ fontSize: 160, color: "#ffffff" }}
              onClick={handleClick}
            />
            <IconComponent
              icon={"ImageAdding"}
              sx={{ fontSize: 160, color: "#ffffff" }}
              onClick={handleClick}
            />
          </div>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <UploadComponents
            addPhotos={handleaddPhotos}
            setReviewImages={setReviewImages}
            tag={tag}
            receiver={receiver}
          />
        </Popover>

        <div>
          <button
            className={styles.submitreview}
            onClick={() => {
              setLoading(true);
              reviewImages.length > 0
                ? uploadReviewImages(reviewImages)
                : sendReview();
            }}
          >
            Submit Review
          </button>
          <button
            className={styles.submitreview}
            onClick={() => {
              setLoading(true);
              reviewImages.length > 0
                ? uploadReviewImages(reviewImages)
                : sendReview();
            }}
          >
            Submit Review as Anonymous
          </button>
        </div>
      </div>
    </div>
  );
};

export default WriteReviewPage;

export const UploadComponents = ({
  setReviewImages,
  setLogo,
  addPhotos,
  tag,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const webcamRef = useRef(null);

  const { phoneNumber } = useContext(Context);

  const handleOpen = () => {
    setOpen(true);
    setIsCameraOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsCameraOpen(false);
  };

  const getFilename = (tag, extension) => {
    const date = moment().format("YYYY-MM-DD");
    const time = moment().format("HH-mm-ss");
    return `${tag}-${phoneNumber}-${date}-${time}.${extension}`;
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      await addImages(files);
    }
  };

  const addImages = async (files) => {
    const newImages = {};
    for (let img of files) {
      const extension = img.name.split(".").pop();
      const name = getFilename("review", extension);
      const imageUrl = URL.createObjectURL(img);
      newImages[name] = { file: img, url: imageUrl };

      await addPhotos(img, name);
    }
    setReviewImages((prev) => ({ ...prev, ...newImages }));
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    const file = new File([blob], getFilename("review") + ".jpg", {
      type: "image/jpeg",
    });
    await addImages([file]);
    handleClose();
  };

  const videoConstraints = {
    width: "400px",
    height: "500px",
    facingMode: "user",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "3rem",
        gap: "1rem",
      }}
    >
      <button
        style={{
          cursor: "pointer",
          width: "120px",
          height: "30px",
          padding: "2px",
          background: "#af002b",
          color: "#fff",
          border: "none",
        }}
        onClick={handleOpen}
      >
        Camera
      </button>

      <label
        htmlFor="image-upload"
        style={{
          cursor: "pointer",
          width: "120px",
          height: "30px",
          padding: "2px",
          background: "#af002b",
          color: "#fff",
          border: "none",
          display: "inline-block",
          textAlign: "center",
        }}
      >
        + Add Images
      </label>
      <input
        id="image-upload"
        type="file"
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/webp"
        onChange={handleImageChange}
        multiple
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{}}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            height: "600px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isCameraOpen && (
            <div className="webcam-img" style={{ marginBottom: "1rem" }}>
              <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={320}
                videoConstraints={videoConstraints}
              />
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={capture}
            sx={{ marginBottom: "1rem" }}
          >
            Capture
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
