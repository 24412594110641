import React from "react";

import ClipLoader from "react-spinners/ClipLoader";
import style from "./LoadingScreen.module.css";
import colors from "../../../assets/colors";
const LoadingScreen = () => {
  return (
    <div className={style.screenLoading}>
      <ClipLoader
        color={"brown"}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingScreen;

export const LoadingResults = ({ width }) => {
  const containerStyle = width
    ? { flex: 0.3 }
    : {
        flex: 0.2,
        flexDirection: "column",
      };
  const _width = width ? width * 0.15 : "100%";
  return (
    <div style={containerStyle} className={style.loadingContainer}>
      <div
        style={{
          flex: width ? 0.5 : "none",
          width: _width,
          height: 150,
          borderRadius: 5,
          backgroundColor: colors.lightGrey,
        }}
      />

      <div
        // style={{ display: "flex", flexDirection: "column", flex: 1, rowGap: 5 }}
        className={style.dataContainer}
      >
        <div style={{ backgroundColor: colors.lightGrey, height: 20 }} />
        <div style={{ backgroundColor: colors.lightGrey, height: 16 }} />
        <div style={{ backgroundColor: colors.lightGrey, height: 16 }} />
        <div
          style={{
            backgroundColor: colors.lightGrey,
            height: 32,
            marginTop: "auto",
          }}
        />
      </div>
    </div>
  );
};
