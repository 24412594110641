import React, { useContext, useState } from "react";
import { CardContent, Typography, Box } from "@mui/material";
import { CircleIcon, IconComponent } from "../../../../Icons/IconComponent";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import style from "./SuggestionCard.module.css";
import { StarRating, actionCall } from "../BussinessCards/BusinessCard";
import { handleOpenLink } from "../../Details/Details";
import LoadingScreen from "../../Loading/LoadingScreen";
import colors from "../../../../assets/colors";
import { Fonts } from "../../../../assets/fonts";
import { BorderColor } from "@mui/icons-material";

export const circleIconStyle = {
  fontSize: 18,
  color: "#AF002B",
  width: 40,
  height: 40,
  borderRadius: "50%",
  border: 1,
  padding: "4px",
};

const SuggestionCard = ({ item }) => {
  const { baseUrl, imageBaseUrl, sessionId, phoneNumber } = useContext(Context);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const navigate = useNavigate();
  const [logoLoading, setLogoLoading] = useState({});
  const actionObject = {
    viewerPhone: phoneNumber,
    receiverPhone: item.phoneNumber,
    viewerType: "customer",
    receiverType: item.itemType,
  };

  const handleClick = () => {
    actionObject.actionType = "view";
    actionCall(actionObject, baseUrl, sessionId);
    navigate(`/businessprofile/${item.itemType}`, {
      state: {
        actionObject: actionObject,
        avgRating: item.avgRating,
      },
    });
  };

  return (
    <>
      <div
        className={style.HCard}
        onClick={handleClick}
        sx={{
          "&:active": {
            background: "#FFFFFF",
            border: "2px solid white",
            borderRadius: "8px",
          },
        }}
      >
        <div className={style.Hverticalimage}>
          {logoLoading[item.images] === "loading" && <LoadingScreen />}

          {item?.images ? (
            <>
              <img
                onClick={() => {
                  navigate("/Preview", {
                    state: {
                      src: {
                        uri: `${imageBaseUrl}/logo/${item.itemType}/${item.images}`,
                      },
                    },
                  });
                }}
                src={`${imageBaseUrl}/logo/${item.itemType}/${item.images}`}
                alt={item.title}
                style={{
                  // width: "240px",
                  // height: "160px",
                  display:
                    logoLoading[item.images] === "loading" ? "none" : "block",
                }}
              />
            </>
          ) : (
            // <div

            // // style={{
            // //   width: "80px", height: "80px", marginRight: "16px"
            // // }}
            // >

            <IconComponent
              icon={"userEmpty"}
              sx={{
                width: "200px",
                height: "130px",
              }}
            />

            // </div>
          )}
          {item.deal && (
            <div className={style.dealsIcon}>
              <IconComponent
                icon={"localoffer"}
                iconfamily="MaterialCommunityIcons"
                sx={{ fontSize: 16 }}
                color={colors.white}
              />
              <p>Deals</p>
            </div>
          )}
        </div>
        {/* <div> */}
        <CardContent
          sx={{
            fontSize: "1.0rem",
            padding: 1,
          }}
        >
          <Typography
            onClick={() => {
              actionObject.actionType = "view";
              actionCall(actionObject, baseUrl, sessionId);
              handleClick();
            }}
            variant="h9"
            style={{
              // fontFamily: Fonts.dmSansBold,
              fontWeight: 700,
              color: colors.black,
            }}
          >
            {item.name}
          </Typography>
          {item.avgRating != 0 && <StarRating rating={item.avgRating} />}
          {item.types && (
            <Typography
              className={style.HdistanceCrad}
              style={{
                // fontFamily: Fonts.dmSansRegular,
                fontSize: 10,
                color: "#9b9a9c",
              }}
            >
              {item.types}
            </Typography>
          )}
          <Typography
            className={style.HdistanceCrad}
            style={{
              // fontFamily: Fonts.dmSansRegular,
              fontSize: 10,
              color: "#9b9a9c",
            }}
          >
            {item.city} {(item.distanceInMts / 1000).toFixed(2)} Kms
          </Typography>
        </CardContent>

        <div className={style.Hiconscontainer}>
          <CircleIcon
            icon={"phone"}
            text={"Call"}
            onClick={() => {
              actionObject.actionType = "call";
              actionCall(actionObject, baseUrl, sessionId);
              handleOpenLink(`tel:${item.phoneNumber}`);
            }}
          />
          <CircleIcon icon={"messageNotif"} text={"Message"} />
          {item.homeDelivery && (
            <CircleIcon icon={"homeDelivery"} text={"Delivery"} />
          )}
          {item.allTimeAvailable && (
            <CircleIcon icon={"twentyFourSeven"} text={"24/7"} />
          )}
        </div>
      </div>
    </>
  );
};

export default SuggestionCard;
